import {
    Avatar,
    Badge,
    Button,
    Cell,
    InlineButtons,
    Input,
    List,
    Placeholder,
    Section,
    Text,
    Title
} from "@telegram-apps/telegram-ui";
import {
    InlineButtonsItem
} from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import {
    InlineButtonsContext
} from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/InlineButtonsContext";
import {
    SectionHeader
} from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import {
    useLocation,
    useNavigate
} from "react-router-dom";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import {
    useEffect,
    useState
} from "react";
import {
    getCards,
    getCardsOpen,
    getTopup,
    postAddress
} from "../api/api";
import Loader from "../helpers/loader";
import {
    Icon20Copy
} from "@telegram-apps/telegram-ui/dist/icons/20/copy";
import QRCode from "qrcode.react";
import WarnIMG from "./../assets/img/warn.png"
import VibrateModule from "../helpers/vibrate";
import MainButton from "../helpers/mainbutton";
import { useNotification } from "../helpers/notification";

const Topup = ({ me, rates }) => {
    const nav = useNavigate();
    const [topup, setTopup] = useState(null);
    const [selectedCur, setSelectedCur] = useState(null);
    const [selectedNet, setSelectedNet] = useState(null);
    const [step, setStep] = useState(1);

    const [loading, setLoading] = useState(false)

    const [addressFromBack, setAddressFromBack] = useState(null)

    const [amount, setAmount] = useState('');

    const {showNotification} = useNotification()

    async function fetchData() {
        const res = await getTopup();
        if (res.status === "error") {
            return showNotification("Error", res.error.message, "error")
        }
        setTopup(res.data);
    }

    useEffect(() => {
        fetchData();
    }, []);


    async function fetchAddress() {
        if(loading) return
        if (!amount || isNaN(amount) || Number(amount) <= 0) return showNotification("Error", "Enter correct amount", "error")//toast.error("Enter correct amount");
    
        setLoading(true)
        window.Telegram.WebApp.MainButton.showProgress(true)
        try {
            const currency = (selectedCur === "usd" && selectedNet === "trc20") ? "usdt-trc" : 
                             (selectedCur === "usd" && selectedNet === "bep20") ? "usdt-bep" : 
                             selectedCur === "bnb" ? "bnb" : 
                             selectedCur === "trx" ? "trx" : null;
    
            if (!currency) {
                return showNotification("Error", "Invalid currency or network", "error")//toast.error("Invalid currency or network");
            }
    
            const res = await postAddress({ currency, amount: Number(amount) });
            console.log(res);
    
            if (res.status === "error") {
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setStep(4)
            setAddressFromBack(res.data);
        } finally {
            window.Telegram.WebApp.MainButton.hideProgress()
            setLoading(false)
        }
    }

    const handleClick = () => {
        if (step === 1) {
            if(!selectedCur) return
            if (["trx", "bnb"].includes(selectedCur)) {
                setStep(3);
            } else if (["ton", "bbx"].includes(selectedCur)){
                setStep(4);
            } else {
                setStep(2)
            }
        } else if (step === 2) {
            if(!selectedNet) return
            if(["usd"].includes(selectedCur) && selectedNet === "ton") {
                setStep(4);
            } else {
                setStep(3);
            }
            
        } else if (step === 3) {
            fetchAddress(); // Assume handleConfirm is replaced with fetchAddress
        }
    };

    /*

    useEffect(() => {
        const mainButton = window.Telegram.WebApp.MainButton;
    
        const handleClick = () => {
            if (step === 1) {
                if (["trx", "bnb"].includes(selectedCur)) {
                    setStep(3);
                } else if (["ton", "bbx"].includes(selectedCur)){
                    setStep(4);
                } else {
                    setStep(2)
                }
            } else if (step === 2) {
                if(["usd"].includes(selectedCur) && selectedNet === "ton") {
                    setStep(4);
                } else {
                    setStep(3);
                }
                
            } else if (step === 3) {
                fetchAddress(); // Assume handleConfirm is replaced with fetchAddress
            }
        };
    
        if (step === 1 && selectedCur) {
            mainButton.offClick(handleClick);
            mainButton.disable();
            mainButton.show();
            mainButton.setText("Confirm");
            mainButton.enable();
            mainButton.onClick(handleClick);
        } else if (step === 2 && selectedNet) {
            mainButton.offClick(handleClick);
            mainButton.disable();
            mainButton.show();
            mainButton.setText("Confirm");
            mainButton.enable();
            mainButton.onClick(handleClick);
        } else if (step === 3) {
            mainButton.offClick(handleClick);
            mainButton.disable();
            mainButton.show();
            mainButton.setText("Confirm");
            mainButton.enable();
            mainButton.onClick(handleClick);
        } else {
            mainButton.hide();
            mainButton.offClick(handleClick);
        }
    
        return () => {
            mainButton.offClick(handleClick);
            mainButton.hide();
        };
    }, [selectedCur, selectedNet, step, amount, addressFromBack]);
    
    
    */

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCurFromParams = queryParams.get("selectedCur");

    useEffect(() => {
        setSelectedCur(selectedCurFromParams ? selectedCurFromParams : "");
        if(selectedCurFromParams === "bnb" || selectedCurFromParams === "trx") {
            return setStep(3)
        }
        if (selectedCurFromParams === "ton" || selectedCurFromParams === "bbx") {
            setStep(4);
        } else if (selectedCurFromParams) {
            setStep(2)
        }
    }, [selectedCurFromParams]);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showNotification("Copied!", "", "success")
        })
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    if (topup === null) return <Loader />;
    return (
        <List>
            <Back />
            {step === 4 && <Section>
                <Cell className="warn" description="Comment is required! Otherwise you may lose your assets" before={<Avatar src={WarnIMG} />}>
                           Attention!
                    </Cell>
            </Section>}
            
                {step === 1 ? (
                    <>
                    <Section>
                        <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE CURRENCY FOR TOP-UP</SectionHeader>
                        <VibrateModule>
                        <Cell onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            if (selectedCur === "ton") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("ton");
                        }} disabled style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedCur === "ton" && <Badge type="dot" />} subtitle={`Temporarily unavailable`} after={/*<Text>${(Number(me?.user?.balances?.ton) * Number(rates?.ton?.usd)).toFixed(2)}</Text>*/<></>} before={<Avatar src="/img/ton.png" />}>
                            Toncoin
                        </Cell>
                        </VibrateModule>
                        <VibrateModule>
                        <Cell onClick={() => {
                            if (selectedCur === "usd") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("usd");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedCur === "usd" && <Badge type="dot" />} subtitle={/*`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0} USD`*/<></>} after={/*<Text>${`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0}`}</Text>*/<></>} before={<Avatar src="/img/usdt.png"/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                        { /* <Cell onClick={() => {
                            if (selectedCur === "trx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("trx");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedCur === "trx" && <Badge type="dot" />} subtitle={/*`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0} USD`<></>} after={/*<Text>${`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0}`}</Text><></>} before={<Avatar src="/img/trx.png"/>}>
                            TRON
                        </Cell>
                        <Cell onClick={() => {
                            if (selectedCur === "bnb") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bnb");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedCur === "bnb" && <Badge type="dot" />} subtitle={/*`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0} USD`<></>} after={/*<Text>${`${me?.user?.balances.usd ? Number(me?.user?.balances?.usd).toFixed(2) : 0}`}</Text>*></>} before={<Avatar src="/img/bnb.png"/>}>
                            Binance Coin
                        </Cell> */}
                        
                        
                        {/*<VibrateModule>
                        <Cell onClick={() => {
                            if (selectedCur === "bbx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bbx");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedCur === "bbx" && <Badge type="dot" />} before={<Avatar src="/img/bbx.png"/>}>
                            BITBIX
                        </Cell>
                        </VibrateModule>*/}
                        
                    </Section>
                    {selectedCur && <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>}
                    </>
                ) : step === 2 ? (
                    <>
                    <Section>
                        <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE NETWORK FOR TOP-UP</SectionHeader>
                        <VibrateModule>
                        <Cell style={{paddingTop:"6px", paddingBottom:"6px"}} onClick={() => {
                            if (selectedNet === "ton") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("ton");
                        }} titleBadge={selectedNet === "ton" && <Badge type="dot" />} before={<Avatar src="/img/ton.png" />}>
                            TON
                        </Cell>
                        </VibrateModule>
                        <VibrateModule>
                        <Cell disabled onClick={() => {
                            return showNotification("Coming soon", "", "error")
                            return toast.error('Coming soon')
                            if (selectedNet === "trc20") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("trc20");
                        }} description="Coming soon..." style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedNet === "trc20" && <Badge type="dot" />} before={<Avatar src="/img/trx.png" />}>
                            TRC-20
                        </Cell>
                        </VibrateModule>
                        {/* <Cell onClick={() => {
                            if (selectedNet === "bep20") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("bep20");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedNet === "bep20" && <Badge type="dot" />} before={<Avatar src="/img/bnb.png" />}>
                            BEP-20
                        </Cell> */}
                    </Section>
                    <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>
                    </>
                ) : step === 3 ? <><Section>
                <List style={{marginBottom:"10px", marginTop:"10px"}}>
                    <Input placeholder="Amount" type="number" value={amount} onChange={handleAmountChange} />
                </List>
                </Section><MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/></> : (
                    <Section className="topup">
                    
                    {selectedCur === "usd" && !addressFromBack ? (
                            <div style={{margin:"14px 0"}} >
                                <Placeholder
                                    
                                    action={<Button size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(topup.usdt[selectedNet].address)}>Copy address</Button>}
                                    header={topup.usdt[selectedNet].address}
                                    description={`Your ${String(selectedCur == "usd" ? "usdt" : selectedCur).toUpperCase()} (${String(selectedNet === "trc20" ? "TRC-20" : selectedNet === "bep20" ? "BEP-20" :selectedNet).toUpperCase()}) address`}
                                >
                                    <div className="qr__bg">
                                    <QRCode value={`ton://transfer/${!addressFromBack ? topup.usdt[selectedNet].address :addressFromBack}?text=${!addressFromBack  && topup.usdt[selectedNet].payload}`} size={150} />
                                    </div>
                                </Placeholder>
                                {!addressFromBack &&  <List>
                                    <label>
                                        <div style={{paddingBottom:"4px"}}>
                                            <Text>Comment for top-up</Text>
                                        </div>
                                        <Input value={topup.usdt[selectedNet].payload} readOnly/>
                                    </label>
                                    <Button style={{marginTop:"10px"}} size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(topup.usdt[selectedNet].payload)}>Copy comment</Button>
                                </List>}
                            </div>
                        ) : !addressFromBack ?  <div style={{margin:"14px 0"}}>
                        <Placeholder
                            action={<Button size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(selectedCur === "bbx" ? topup[selectedCur].ton.address :topup[selectedCur].address)}>Copy address</Button>}
                            header={selectedCur === "bbx" ? topup[selectedCur].ton.address :topup[selectedCur].address}
                            description={`Your ${String(selectedCur).toUpperCase()} (${String(selectedCur).toUpperCase()}) address`}
                        >
                            <div className="qr__bg">
                            <QRCode value={`ton://transfer/${selectedCur === "bbx" ? topup[selectedCur].ton.address :topup[selectedCur].address}?text=${selectedCur === "bbx" ? topup[selectedCur].ton.payload : topup[selectedCur].payload}`} size={150} />
                            </div>
                        </Placeholder>
                        {!addressFromBack && <List>
                            <label>
                                <div style={{paddingBottom:"4px"}}>
                                    <Text>Comment for top-up</Text>
                                </div>
                                <Input value={selectedCur === "bbx" ? topup[selectedCur].ton.payload : topup[selectedCur].payload} readOnly/>
                            </label>
                            <Button style={{marginTop:"10px"}} size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(selectedCur === "bbx" ? topup[selectedCur].ton.payload : topup[selectedCur].payload)}>Copy comment</Button>
                        </List>}
                    </div> : <>
                    <div style={{margin:"14px 0"}}>
                    <Placeholder
                            action={<Button size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(addressFromBack.address)}>Copy address</Button>}
                            header={addressFromBack.address}
                            description={`Your ${String(selectedCur == "usd" ? "usdt" : selectedCur).toUpperCase()} ${String(selectedNet === "trc20" ? "(TRC-20)" : selectedNet === "bep20" ? "(BEP-20)" : "").toUpperCase()} address`}
                        >
                    </Placeholder>
                     <List>
                            <label>
                                <div style={{paddingBottom:"4px"}}>
                                    <Text>Amount</Text>
                                </div>
                                <Input value={addressFromBack.amount} readOnly/>
                            </label>
                            <Button style={{marginTop:"10px"}} size="s" stretched before={<Icon20Copy />} mode="plain" onClick={() => copyToClipboard(addressFromBack.amount)}>Copy amount</Button>
                            <div style={{paddingBottom:"4px"}}>
                                    <Text className="topup__info">The address is valid for 15 minutes. If you send funds after the expiration period, they may be lost.</Text>
                            </div>
                    </List>
                    </div>
                    </>}
                    </Section>
                )}
        </List>
    );
};

export default Topup;
