import CashbackImg from "./../assets/img/transactions/Cashback.png"
import InsImg from "./../assets/img/transactions/Card_issue.png"
import TopupImg from "./../assets/img/transactions/Topup.png"
import WithdrawImg from "./../assets/img/transactions/Withdraw.png"
import RefundImg from "./../assets/img/transactions/Refund.png"
import PaymentImg from "./../assets/img/transactions/Payment.png"

export default (transaction) => {
    return transaction.action === "Account topup" ? TopupImg :
    transaction.action === "Card issuance" ? InsImg :
    transaction.action === "Cashback" ? CashbackImg :
    transaction.action === "Refund" ? RefundImg :
    transaction.action === "Reward" ? TopupImg :
    String(transaction.action).includes("Referral") ? CashbackImg :
    ( transaction.action === "Withdrawal" || transaction.action === "Card top-up") ? WithdrawImg :
    transaction.action === "Payment" ? PaymentImg :
    String(transaction.action).includes("to bank card") ? PaymentImg :

    transaction.image ? transaction.image :
    transaction.type === "income" ? `/img/${transaction.type === "USD" ? "usdt" :
    String(transaction.currency).toLowerCase() }.png` : "" || ""
}