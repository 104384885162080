import { Avatar, Button, List, Placeholder, Text } from "@telegram-apps/telegram-ui"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import MainButton from "../../../../helpers/mainbutton";



const TransferPage = ({transfer, handleClose, head, info}) => {
    const nav = useNavigate()

    const handleClick = async () => {
        handleClose()
    };
    /*
    useEffect(() => {
        const mainButton = window.Telegram.WebApp.MainButton;

        
        
        mainButton.offClick(handleClick);
        mainButton.disable()
        mainButton.show();
        mainButton.enable();
        mainButton.setText("Close");
        mainButton.onClick(handleClick);

        return () => {
            mainButton.offClick(handleClick);
            mainButton.hide();
        };
    }, []);*/

    return (
        <> 
            <List className="full_w"> 
                <div></div>
                <Placeholder header={<Text style={{fontWeight:"700", fontSize:"32px"}}>{transfer.amount} {String(transfer.cur).toLowerCase() === "usd" ? "USDT" : String(transfer.cur).toUpperCase()}</Text>}
                    description={<div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"5px", flexDirection:"column"}}><Text>{head ? head : `to ${transfer?.user?.username ? "@" +transfer?.user?.username : transfer.user.id}`}</Text> <Text className="ordinary__text">{info ? info :" You have successfully completed the transfer!"}</Text></div>}>
                    <Avatar size={64} src="/img/success.png" />
                </Placeholder>
                <div></div>
            </List>
            <MainButton text={"Close"} myButton withoutTabbar func={handleClick}/>
        </>
    )
}

export default TransferPage