import MainButton from "../../../../helpers/mainbutton"
import { Avatar, Cell, Input, Section, Placeholder, Modal, Button, List } from "@telegram-apps/telegram-ui"
import MasterCardImg from "../../../../assets/img/mc.png"
import VisaImg from "../../../../assets/img/vis.png"
import Back from "../../../../helpers/back"
import VibrateModule from "../../../../helpers/vibrate"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import { getPhoneTopupProducts, postPhoneTopup, postPhoneTopupPayment, sendToCard } from "../../../../api/api"
import { useState } from "react"
import TransferPage from "../../transactions/transactionPages/transfer"
import Loader from "../../../../helpers/loader"
import { useNotification } from "../../../../helpers/notification"

const ToCardService = ({cards, selectedCountry, loading, getSelectedProvider, step, setStep, setSelectedProvider, setLoading}) => {
    const [payment, setPayment] = useState(null)
    const [cardNumber, setPhoneNumber] = useState("")

    const [cardAmount, setPhoneAmount] = useState("")

    const {showNotification} = useNotification()

    const [modalActive, setModalActive] = useState(false)

    const selectPayment = async () => {
        setModalActive(true)
    }

    
    

    const [result, setResult] = useState(null)
    
    

    async function handleSubmitOrder() {
        if(loading === true) return 
        setLoading(true)
        try {
            const res = await sendToCard({payData: payment.value, payType: payment.type , amount: Number(cardAmount), cardNumber: cardNumber, country: selectedCountry?.isoName});
            if (res.status === "error") {
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setResult(res.data)
            setModalActive(false)
            return showNotification("Success","Phone topped up successfully", "success") 
        } finally {
            setLoading(false)
        }
    }

    if(result) return <TransferPage handleClose={() => {
        setSelectedProvider(null)
        setResult(null)
        setStep(2)
    }} transfer={{amount:Number(cardAmount).toFixed(2), cur: "USD" }} head={"Card transfer"} info={"You have successfully completed the payment!"}/>
    return (
        <List>
            {getSelectedProvider()}
            {step === 3 ? <>
            <Back callback={() => {
                setSelectedProvider(null)
                setStep(2)
            }}/>

            <Section>
                <Input
                    value={cardNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter card number"
                    className="input"
                />
            </Section>
            {!modalActive && <MainButton text={"Confirm"} func={() => setStep(4)} loading={loading} myButton withoutTabbar/>}
            </> :  step === 4 ?  <>

                <Back callback={() => {
                    setStep(3)
                    
                }}/>
                <Section header={`Enter amount in USD`}>
                        <Input
                            value={cardAmount}
                            onChange={(e) => setPhoneAmount(e.target.value)}
                            placeholder="Enter amount"
                            className="input"
                        />
                </Section>
                <Section>
                <Cell className="warn"description={"Minimum amount 5 USD maximum 5000 USD"}>
                    Limits
                </Cell>
                </Section>
                <MainButton text={"Confirm"} func={() => {
                    if(isNaN(cardAmount)) return showNotification("Error", "Enter correct amount", "error")
                    if(Number(cardAmount) < 5 || Number(cardAmount) > 5000) return showNotification("Error", "Minimum amount 5 USD maximum 5000 USD", "error")  
                        setStep(5)
                }} loading={loading} myButton withoutTabbar/>
            
            </> :  step === 5 ?  <>
                <Back callback={() => {
                    setModalActive(false)
                    setStep(4)
                    
                }}/>


                    <Section header="Select payment method">
                        {/*<VibrateModule>
                        <Cell subhead="Crypto" onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "ton"
                            })
                        }} before={<Avatar size={48} src={'/img/ton.png'}/>}>
                            TON
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    <Section >
                    <VibrateModule>
                        <Cell onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "usdt"
                            })
                        }} subhead="Crypto" before={<Avatar size={48} src={'/img/usdt.png'}/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                    {cards.length > 0 ? cards?.map(card => {
                        return (
                            <Section>
                                <VibrateModule>
                                <Cell disabled subhead={"Card"} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} 
                                onClick={() => {
                                    return
                                    selectPayment()
                                    setPayment({
                                        type: "card",
                                        value: card.id
                                    })
                                }}>
                                    {card.brand} {String(card.mask).slice(13).replace(" ", "")}
                                </Cell>
                                </VibrateModule>
                            </Section>
                        )
                    }) : "" }

              

                </>  : <Loader /> }

                <Modal
                open={modalActive}
                header={<ModalHeader after={<ModalClose onClick={() => {
                    setModalActive(false)
                }}><Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} /></ModalClose>}>Confirm payment</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description={`You will send ${Number(cardAmount).toFixed(2)} USD to ${cardNumber} card`}
                    header="Are you sure?"
                    action={<Button onClick={() => {
                        handleSubmitOrder()
                    }} stretched loading={loading}>Confirm</Button>}
                >
                </Placeholder>
            </Modal>
        </List>
    )
}

export default ToCardService