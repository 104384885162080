import { Avatar, Cell, InlineButtons, List, Text } from "@telegram-apps/telegram-ui";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { format } from "date-fns";
import { useEffect } from "react";
import toast from "react-hot-toast";
import CashbackImg from "./../assets/img/cashback.png"
import InsImg from "./../assets/img/iniscard.png"
import TopupImg from "./../assets/img/top_up.png"
import WithdrawImg from "./../assets/img/withdraw.png"
import TransferImg from "./../assets/img/transfer.png"
import PaymentImg from "./../assets/img/payment.png"
import { useNotification } from "../helpers/notification";
import Back from "../helpers/back";
import getImageSrc from "../utils/getImageSrc";

const Transaction = ({ transaction, onBack, type = "main" }) => {
    const {showNotification} = useNotification()
      const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showNotification("Copied!", "", "success")
        })
    };

    
    return (
        <div className="transaction-overlay">
            <Back callback={onBack} />
            <List>
                <Cell className="transaction__user" before={<Avatar size={40} acronym={transaction.action.charAt(0)} src={getImageSrc(transaction)} />}>
                    {transaction.action} <span className="transaction__user__name">{transaction.name || ""}</span>
                </Cell>
                <Text className={`transaction__amount ${type === "main" && transaction.type === "income" ? "receive__trans" : ""}`} style={{textDecoration: (transaction.data && transaction.data.length > 0 && String(transaction.data.find(ob => ob.title == "Status")?.value).toLowerCase() === "error"  || String(transaction.data.find(ob => ob.title == "Status")?.value).toUpperCase() === "FAILED") && "line-through", fontSize: String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 6 ? "32px" : String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 9 ? "20px" : "56px" }}>
                    {type === "main" && transaction.type === "income" || transaction.type === "cashback"  ? "+" : "-"}{Number(String(transaction.amount).replace("-", "")).toFixed(2)} <span style={{fontSize: String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 6 ? "28px" : String(Number(Math.abs(transaction.amount)).toFixed(2)).length > 9 ? "18px" : "46px" }}>{String(transaction.currency).toUpperCase()}</span>
                </Text>
                <Text className="transaction__date">{format(new Date(transaction.date), 'PPpp')}</Text>
                <InlineButtons className="transaction__buttons">
                    {transaction.data && transaction.data.map(d => {
                        if(!d.value) return 
                        return (
                            <InlineButtonsItem onClick={() => copyToClipboard(d.value)} className="transaction__button" mode="gray" text={<Text className="transaction__button__value">{d.value }</Text>}>
                                <Text className="transaction__button__title">{d.title}</Text>
                            </InlineButtonsItem>
                        )
                    })}
                    
                </InlineButtons>
            </List>
        </div>
    );
}

export default Transaction;
