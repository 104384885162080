import { Avatar, Chip, InlineButtons, Text } from "@telegram-apps/telegram-ui"
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem"
import { Icon24Arrow2SquarepathOutline, Icon24PaymentCardOutline, Icon28ArrowUpCircleOutline, Icon28MoneySendOutline, Icon32SendCircle, Icon36CoinsStacks2Outline } from "@vkontakte/icons"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import VibrateModule from "../../../helpers/vibrate"
import { List } from "react-virtualized"
import { useNotification } from "../../../helpers/notification"


const InlineButtonsComponent = ({me, fetchDataKyc}) => {
    const {showNotification} = useNotification()
    const nav = useNavigate()
    return (
        <>
            <InlineButtons>
                <VibrateModule>
                    <Chip onClick={() => {
                        /*if(!me?.user?.haveAccess) return toast.error("You're on the wait list")
                        if(me?.user?.sumsubVerified === false) return fetchDataKyc()*/
                        nav("/topup")
                    }} mode="mono" className="main__button__nav" before={<Icon28ArrowUpCircleOutline width={16} height={16} style={{rotate:"180deg"}} />}>
                        Top-up
                    </Chip>
                </VibrateModule>
                <VibrateModule>
                    <Chip onClick={() => {
                        if(Number(me?.user?.balances?.usd) <= 0) return showNotification("Error", "Not enough balance", "error"); 
                        //if(!me?.user?.haveAccess) return showNotification("Error","You're on the wait list", "error") 
                        if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                        nav("/withdraw")
                    }} mode="outline" className="main__button__nav" before={<Icon28ArrowUpCircleOutline width={16} height={16}  />}>
                        Withdraw
                    </Chip>
                </VibrateModule>
                
                        {/*<VibrateModule>
                            <InlineButtonsItem className="main__button__nav__block" text={<Text className="main__button__nav"><Icon28ArrowUpCircleOutline width={16} height={16} style={{rotate:"180deg"}} /> Top-up</Text>} mode="gray" onClick={() => {
                                if(!me?.user?.haveAccess) return toast.error("You're on the wait list")
                                if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                                nav("/topup")
                            }}>
                                
                            </InlineButtonsItem>
                        </VibrateModule>
                        <VibrateModule>
                            <InlineButtonsItem className="main__button__nav__block" text={<Text className="main__button__nav"><Icon28ArrowUpCircleOutline width={16} height={16}  /> Withdraw</Text>} mode="gray" onClick={() => {
                                if(!me?.user?.haveAccess) return toast.error("You're on the wait list")
                                if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                                nav("/withdraw")
                            }}>
                                
                            </InlineButtonsItem>
                        </VibrateModule>
                        
                        <VibrateModule>
                            <InlineButtonsItem text="Transfer" onClick={() => {
                                if(!me?.user?.haveAccess) return toast.error("You're on the wait list")
                                if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                                nav("/transfer")
                                }}>
                                    <Icon24Arrow2SquarepathOutline width={32} height={32}/>
                                    
                            </InlineButtonsItem>
                        </VibrateModule>*/}
             </InlineButtons>
             {/*me?.user?.haveAccess == true && <VibrateModule>
                <Chip  onClick={() => {
                    nav("/earn")
                }} mode="outline" className="main__button__nav" before={<Icon36CoinsStacks2Outline width={16} height={16}  />}>
                    Earn
                </Chip>
            </VibrateModule>*/}
            {<VibrateModule>
                <Chip onClick={() => {
                    nav("/open/card")
                }} mode="outline" className="main__button__nav" before={<Icon24PaymentCardOutline width={16} height={16}  />}>
                    New card
                </Chip>
            </VibrateModule>}
        </>
    )
}

export default InlineButtonsComponent