import {
    Avatar,
    Badge,
    Banner,
    Button,
    Cell,
    InlineButtons,
    List,
    Placeholder,
    Section,
    Select,
    Switch,
    Text,
    Title,
} from "@telegram-apps/telegram-ui";
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem";
import { InlineButtonsContext } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/InlineButtonsContext";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { getCardsOpen, postCard } from "../api/api";
import Loader from "../helpers/loader";
import SuccessOpenCard from "./components/cards/successOpen";
import VibrateModule from "../helpers/vibrate";
import { useNotification } from "../helpers/notification";

const OpenCard = ({ me, rates, fetchDataKyc }) => {
    const nav = useNavigate();

    const [cards, setCards] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [step, setStep] = useState(1);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("usd");
    const [useBonuses, setUseBonuses] = useState(false);
    const [newID, setNewID] = useState(null);
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();

    useEffect(() => {
        async function preloadImages(cards) {
            const preloadPromises = cards.map((card) => {
                return new Promise((resolve) => {
                    const img = new Image();
                    img.src = card.image;
                    img.onload = () => resolve(card); // Resolve when the image is loaded
                });
            });

            return await Promise.all(preloadPromises); // Wait for all images to preload
        }

        async function fetchData() {
            const res = await getCardsOpen();
            if (res.status === "error") {
                return showNotification("Error", res.error.message, "error");
            }
            const preloadedCards = await preloadImages(res.data.cards); // Preload card images
            setCards({ ...res.data, cards: preloadedCards });
        }

        window.Telegram.WebApp.expand();
        fetchData();
    }, []);

    const confirmCard = async () => {
        setLoading(true);
        try {
            const res = await postCard({
                type: selectedCard.id,
                paymentCurrency: selectedPaymentMethod,
                useBonuses: useBonuses,
            });
            if (res.status === "error") {
                return showNotification("Error", res.error?.message || res.error || "Error", "error");
            }
            setNewID(res.data);
            showNotification("Success", "Successfully released", "success");
        } finally {
            setLoading(false);
        }
    };

    if (newID !== null) return <SuccessOpenCard id={newID.id} brand={newID.brand} name={newID.type} />;

    if (cards === null) return <Loader />;


    console.log(selectedCard)
    return (
        <List>
            <Back />
            {step === 1 ? (
                <>
                    {cards?.cards ? (
                        cards.cards.map((card) => (
                            <Banner
                                style={{ padding: "20px", marginBottom: "12px" }}
                                background={<img src={card.image} style={{ width: '100%', height: "100%", objectFit: "cover" }} />}
                                header={<><Text style={{display:"block", marginBottom:"5px"}} className="hint">{card.canUseBonuses && "The bonuses can be used"}</Text>{card.name}</>}
                                subheader={"$" + card?.price}
                                type="section"
                            >
                                <VibrateModule>
                                    <Button
                                        mode="white"
                                        size="s"
                                        onClick={() => {
                                            if (Number(me?.user?.totalBalance) < Number(card?.price))
                                                return showNotification("Error", "Not enough balance", "error");
                                            if (me?.user?.sumsubVerified === false) {
                                                return fetchDataKyc();
                                            }
                                            setSelectedCard(card);
                                            setStep(2);
                                        }}
                                    >
                                        Issue
                                    </Button>
                                </VibrateModule>
                            </Banner>
                        ))
                    ) : (
                        <Placeholder style={{ margin: "0 0 16px 0" }} description="Card list is empty"></Placeholder>
                    )}
                </>
            ) : (
                <Section>
                    <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE PAYMENT METHOD</SectionHeader>
                    <List style={{ marginBottom: "8px" }}>
                        <Select value={selectedPaymentMethod} onChange={(e) => setSelectedPaymentMethod(e.target.value)}>
                            <option value={"usd"}>USDT</option>
                        </Select>

                        {(me?.user?.balances?.bonus && me?.user?.balances?.bonus > 0 && selectedCard.canUseBonuses == true) ? (
                            <Cell
                                style={{ paddingLeft: "0px", paddingRight: "0px" }}
                                description={`You have ${me?.user?.balances?.bonus}$`}
                                after={<Switch checked={useBonuses} onChange={(e) => setUseBonuses(e.target.checked)} />}
                            >
                                Use bonuses?
                            </Cell>
                        ) : ""}
                    </List>
                    {selectedPaymentMethod !== null && (
                        <Button
                            style={{ paddingBottom: "8px" }}
                            size="m"
                            onClick={() => confirmCard()}
                            stretched
                            loading={loading}
                        >
                            Pay {((useBonuses ? Math.max(0, selectedCard.price - me?.user?.balances?.bonus) : selectedCard.price) / 1).toFixed(2)} {String(selectedPaymentMethod).toUpperCase()}
                        </Button>
                    )}
                </Section>
            )}
        </List>
    );
};

export default OpenCard;
