import { Avatar, Cell, Text } from "@telegram-apps/telegram-ui"
import CashbackImg from "../../../assets/img/cashback.png"
import InsImg from "../../../assets/img/iniscard.png"
import TopupImg from "../../../assets/img/top_up.png"
import WithdrawImg from "../../../assets/img/withdraw.png"
import TransferImg from "../../../assets/img/transfer.png"
import PaymentImg from "../../../assets/img/payment.png"
import { format } from "date-fns"
import VibrateModule from "../../../helpers/vibrate"
import getImageSrc from "../../../utils/getImageSrc"

const TransactionData = ({transaction, index, handleTransactionClick, cardTransaction = false}) => {
    if(cardTransaction) {
        return (
            <VibrateModule>
            <Cell
                onClick={() => handleTransactionClick(transaction)} // Handle click event
                key={transaction.id}
                after={<div className={`cell__transactions__after ${transaction.type === "income" ? "GREEN" : (transaction.type === "REVERSED" ? "RED" : "GRAY")}`}>
                    <Text className="cell__transactions__after__amount" style={{textDecoration: transaction.status === "FAILED" && "line-through"}}> {transaction.status === "REVERSED" ? "" : "-"}{Number(String(transaction.amount).replace("-","")).toFixed(2)} {transaction.currency}</Text>
                    <Text className="hint">{String(transaction.status).charAt(0).toUpperCase() + String(transaction.status).slice(1).toLowerCase()}
                    </Text>
                    </div>} 
                before={<Avatar size={48} acronym={transaction.merchant.charAt(0)} src={`https://innature.store/public/mcc/${transaction.mcc}` || ""} />} 
                description={format(new Date(transaction.transactionDate), 'PPpp')}
            >
                {transaction.merchant}
            </Cell>
            </VibrateModule>
        )
    } else {
        return (
            <VibrateModule>
            <Cell
                onClick={() => handleTransactionClick ? handleTransactionClick(transaction) : ""}
                key={index}
                after={<div className={`cell__transactions__after ${transaction.type === "income" || transaction.type === "cashback"  ? "GREEN" : (transaction.type === "outcome" ? "RED" : "GRAY")}`}>
                    <Text className="cell__transactions__after__amount" style={{textDecoration: (transaction.data && transaction.data.length > 0 && String(transaction.data.find(ob => ob.title == "Status")?.value).toLowerCase() === "error" || String(transaction.data.find(ob => ob.title == "Status")?.value).toLowerCase() === "failed") && "line-through"}}>{transaction.type === "income" || transaction.type === "cashback"  ? "+" : "-"}{Number(String(transaction.amount).replace("-", "")).toFixed(2)} {String(transaction.currency).toUpperCase()}</Text>
                </div>}
                before={<Avatar size={48} acronym={transaction.action.charAt(0)} src={getImageSrc(transaction)} />}
                description={format(new Date(transaction.date), 'PPpp')}
            >
                {transaction.action}
            </Cell>
            </VibrateModule>
        )
    }
    
}

export default TransactionData