import { Banner, Button, List } from "@telegram-apps/telegram-ui"
import React from "react"
import { useNavigate } from "react-router-dom"
import VibrateModule from "../../../../helpers/vibrate"
import { useNotification } from "../../../../helpers/notification"


const Banners = ({me, fetchDataKyc, cards}) => {
    const nav = useNavigate()

    const { showNotification } = useNotification();

    return (
        (me?.user?.sumsubVerified === false && true/*me?.user?.haveAccess*/) ?
        <List>
            <Banner header="To issue a card, go through verification">
                <React.Fragment key=".0">
                    <VibrateModule>
                    <Button size="s" onClick={() => {
                        if(Number(me?.user?.balances?.usd) <= 0) return showNotification("Error", "You need to top-up your balance", "error"); 
                        fetchDataKyc()}
                    }>
                        Go through verification
                    </Button>
                    </VibrateModule>
                </React.Fragment>
            </Banner>
        </List>
        : (cards?.length === 0 && true/*me?.user?.haveAccess*/) ? <List>
            <Banner header={`Nice to meet you, ${me.user.name ? me.user.name : "our user"}. Do you want to issue your first card?`}>
                <React.Fragment key=".0">
                    <VibrateModule>
                    <Button size="s" onClick={() => nav("/open/card")}>
                        Let's go!
                    </Button>
                    </VibrateModule>
                </React.Fragment>
            </Banner>
        </List> 
        : <></>
    )
}

export default Banners

{/*!me.user.haveAccess && <List>
            <Banner header={"Want to get early access?"} subheader={`Deposit $500 or more - get access to all features before anyone else!`}>
                
            </Banner></List>*/}