import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Main from "./pages/main";
import Cards from "./pages/cards";
import Card from "./pages/card/Card";
import Balance from "./pages/balance";
import Transaction from "./pages/transaction";
import { getRates, getUserInfo, postKyc } from "./api/api";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Loader from "./helpers/loader";
import OpenCard from "./pages/openCard";
import Topup from "./pages/topup";
import Withdraw from "./pages/withdraw";
import Transfer from "./pages/transfer";
import OnlinePayments from "./pages/onlinePayment";
import Account from "./pages/account/Account";
import Kyc from "./pages/kyc";
import PinInputComponent from "./pages/PinPage";
import ErrorPage from "./components/ErrorPage";
import { useNotification } from "./helpers/notification";
import PermissionLayout from "./layouts/PermissionLayout";
import Earn from "./pages/components/main/components/earn";
import PaymnetQR from "./pages/paymentQr/PaymentQR";
import HistoryPage from "./pages/HistoryPage/HistoryPage";

function App() {

  const [me, setMe] = useState(null);
  const [rates, setRates] = useState(null);
  const [iframeUrl, setAccessToken] = useState(null);

  const [kyc, setKyc] = useState(false)

  const { showNotification } = useNotification();
  const location = useLocation();

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };

  async function fetchData(ref) {
    const res = await getUserInfo(ref);
    if (res.success === false) {
      return showNotification("Error", res.error.message, "error");
    }
    setMe(res.data);
  }

  async function fetchDataKyc() {
    setKyc(true)
    const res = await postKyc();
    if (res.status === "error") {
      setKyc(false)
      return showNotification("Error", res.error.message, "error");
    }
    //window.Telegram.WebApp.openLink(res.data.iframeUrl)
    setAccessToken(res.data.iframeUrl);
  }

  async function fetchDataRates() {
    const res = await getRates();
    if (res.status === "error") {
      return showNotification("Error", res.error.message, "error");
    }
    setRates(res.data);
  }

  useEffect(() => {
    async function getData() {
      window?.Telegram?.WebApp?.expand();
      window?.Telegram?.WebApp?.disableVerticalSwipes();
      window?.Telegram?.WebApp?.setHeaderColor("#000000");
      window?.Telegram?.WebApp?.setBackgroundColor("#000000");
      const queryParams = getQueryParams();
      const ref = queryParams.get('tgWebAppStartParam');
      await fetchData(String(ref).includes("esim_") ? "" : String(ref).includes("ref_") ? String(ref).replace("ref_", "") : "");
      await fetchDataRates();
    } 
    getData()
  }, []);

  if (me === null || rates === null || !window.Telegram.WebApp.initData) return <Loader />;
  if (!me?.user && me.blocked === true) return <ErrorPage type="pin" />;
  if (!me?.user && me.pinRequired === true) return <PinInputComponent setMe={setMe} me={me} fetchMe={fetchData} />;

  return (
    <div className="app">
      {kyc ? (
        <Kyc iframeUrl={iframeUrl} setAccessToken={setAccessToken} me={me} setKyc={setKyc} />
      ) : (
        <Routes>
          <Route path="/" element={
            <Main me={me} fetchDataKyc={fetchDataKyc} rates={rates} setMe={setMe} fetchMe={fetchData} />
          } />
          <Route path="/earn" element={
            <Earn me={me} page={true} fetchDataKyc={fetchDataKyc} rates={rates} setMe={setMe} fetchMe={fetchData} />
          } />
          <Route path="/account" element={
            <Account me={me} fetchDataKyc={fetchDataKyc} setMe={setMe} />
          } />
          {/*<Route path="/cards" element={
            <PermissionLayout me={me} withoutVerif={true}><Cards me={me} /></PermissionLayout>
          } />*/}
          <Route path="/history" element={
            <PermissionLayout me={me} withoutVerif={true}><HistoryPage me={me} /></PermissionLayout>
          } />
          <Route path="/topup" element={
            <Topup me={me} rates={rates} />
          } />
          <Route path="/withdraw" element={
            <PermissionLayout me={me}><Withdraw me={me} rates={rates} /></PermissionLayout>
          } />
          <Route path="/transfer" element={
            <PermissionLayout me={me}><Transfer me={me} rates={rates} /></PermissionLayout>
          } />
          <Route path="/open/card" element={
            <PermissionLayout me={me} withoutVerif={true}><OpenCard me={me} rates={rates} fetchDataKyc={fetchDataKyc}/></PermissionLayout>
          } />
          <Route path="/card/:id" element={
            <PermissionLayout me={me}><Card me={me} rates={rates} /></PermissionLayout>
          } />
          <Route path="/balance/:type" element={
            <Balance me={me} rates={rates} fetchDataKyc={fetchDataKyc} />
          } />
          <Route path="/online/payments" element={
            <PermissionLayout me={me} withoutVerif={true}><OnlinePayments me={me} setMe={setMe} /></PermissionLayout>
          } />
          <Route path="/payment/:id" element={
            <PermissionLayout me={me}><PaymnetQR me={me} setMe={setMe} /></PermissionLayout>
          } />
          <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
