import { Avatar, Badge, Banner, Button, ButtonCell, Cell, Image, InlineButtons, Input, List, Placeholder, Section, Spinner, Text, Title } from "@telegram-apps/telegram-ui";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import splitNumbers from "../../../../helpers/splitNumbers";
import BoostImg from "../../../../assets/img/boost.png"
import Ximg from "../../../../assets/img/x.jpeg"
import { Icon16CheckOutline } from "@vkontakte/icons";
import { getTasks, postEarn } from "../../../../api/api";
import { useNotification } from "../../../../helpers/notification";
import Back from "../../../../helpers/back";

const Earn = ({me, fetchMe, page = false}) => {
    const {showNotification} = useNotification()
    const [loading, setLoading] = useState(false)
    const [tasks, setTasks] = useState(null)
    const [activeTask, setActiveTask] = useState(null)

    /*async function fetchDataTasks() {
        const res = await getTasks();
        if (res.status === "error") {
            return showNotification("Error", res.error.message, "error")
        }
        setTasks(res.data)
    }*/

    useEffect(() => {
      if(!me || tasks) return
        //if(me.user.haveAccess) return
        //fetchDataTasks()
    }, [me])

    const postEarnData = async (id, earnInfo) => {
        setActiveTask(earnInfo)
        if(loading) return 
        //const toastId = toast.loading("Loading");
        try {
          setLoading(true);
          const data = await postEarn(id);
          if (data.success === true) {
            if (data.data.completed === true) {
                await fetchMe()
                //toast.dismiss(toastId)
                setTimeout(() => {
                  showNotification("Success", "Task completed", "success") 
                }, 300)
            }
          } else {
            if (data?.data?.completed === false) {
              if (earnInfo.type === "telegramSubscribe") {
                return window.Telegram.WebApp.openTelegramLink(earnInfo.payload[1])
              } else if (earnInfo.type === "friends") {
                return showNotification("Error", "Task is not complete", "error") 
              } else if (earnInfo.type === "emoji") {
                return window.Telegram.WebApp.openTelegramLink(earnInfo.payload[0])
              } else if (earnInfo.type === "boost") {
                return window.Telegram.WebApp.openTelegramLink(earnInfo.payload[0])
              } else if (earnInfo.type === "xSubscribe") {
                return window.Telegram.WebApp.openLink(earnInfo.payload[0])
              }
            }
            if (data?.data?.error) {
              return toast.error(data?.data?.error);
            }
          }
        } catch (error) {
          console.log(error);
        } finally {
            //toast.dismiss(toastId)
          setActiveTask(null)
          setLoading(false);
        }
      };


      const imageSrc = {
        telegramSubscribe: "/tasks/sub.png",
        friends: "/tasks/friends.png",
        emoji: "/tasks/emoji.png",
        wallet: "/tasks/wallet.png",
        boost: BoostImg,
        xSubscribe: Ximg
    };

    
    
    const defaultImageSrc = "/tasks/sub.png";
    return (
      <>
      {page && <Back />}
      <List>
        <Section header="Affiliate program">
          <Cell className="ref__baner" description={"and get 10$ for each friend, who activate card"}>
            Invite friends 
          </Cell>
          <Input readOnly value={`https://t.me/bfinancebot/app?startapp=ref_${me.user.uid}`}  className="ref__baner__input"/>
          <Button size="m" stretched style={{paddingBottom:"8px"}} onClick={() => {
            navigator.clipboard.writeText(`https://t.me/bfinancebot/app?startapp=ref_${me.user.uid}`)
            return showNotification("Copied!", "", "success") 
          }}>
            Copy link
          </Button>
        </Section>
      </List>
      <List style={{padding:"8px 18px"}}>
      {/*tasks ? Object.keys(tasks).map((category) => (
              <Section header={category === "categories" ? "" : category} key={category}>
                  {Array.isArray(tasks[category]) &&  tasks[category].map((earn) => {
                      {
                          return (
                              <Cell
                              key={earn.id}
                              onClick={() => {
                                  if(me?.user?.tasksCompletedArray && me?.user?.tasksCompletedArray.includes(earn.id)) return showNotification("Error", "Already completed", "error")  //toast.error("Already completed")
                                  postEarnData(earn.id, earn)
                              }}
                              before={
                                  <Image size={40} src={imageSrc[earn.type] || defaultImageSrc} />
                              }
                              className="cell"
                              after={
                                  me?.user?.tasksCompletedArray && me?.user?.tasksCompletedArray.includes(earn.id) 
                                  ?
                                  <Badge type="number" mode="secondary" style={{ whiteSpace: "nowrap" }}>
                                      <Icon16CheckOutline />
                                  </Badge> 
                                  :
                                  earn.id == activeTask?.id ? <div><Spinner size="s"/></div> : <Badge type="number" style={{ whiteSpace: "nowrap" }}>
                                  {splitNumbers(Number(earn.reward))}{earn.currency === "$" ? "": " "}{earn.currency}
                                  </Badge>
                              }
                              subhead={earn.descriptionEn}
                              >
                              { earn.titleEn}
                              </Cell>
                          )
                      }

                  })}
              </Section>
              
              )) : <Section >
                      <SectionHeader style={{ paddingTop: "16px" }}>TASKS</SectionHeader><div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                  <Spinner />
              </div></Section>*/}
      </List>
  </> 
    )
}

export default Earn