
import { Avatar, Cell, InlineButtons, List, Placeholder, Section, Text, Title } from "@telegram-apps/telegram-ui"
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem"
import { InlineButtonsContext } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/InlineButtonsContext"
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader"
import { useNavigate } from "react-router-dom"
import Back from "../helpers/back"
import toast from "react-hot-toast"
import { useEffect, useState } from "react"
import { getCards } from "../api/api"
import Loader from "../helpers/loader"
import MainButton from "../helpers/mainbutton"
import MasterCardImg from "./../assets/img/mc.png"
import VisaImg from "./../assets/img/vis.png"
import VibrateModule from "../helpers/vibrate"
import TapBar from "./components/tabbar/tabbar"
import { useNotification } from "../helpers/notification"


const Cards = ({me}) => {
    const nav = useNavigate()

    const [cards, setCards] = useState(null)

    const {showNotification} = useNotification()
    

    async function fetchData() {
        const res = await getCards()
        if (res.status === "error") {
            return showNotification("Error",res.error.message, "error") //toast.error();
        }
        setCards(res.data)
    }
    useEffect(() => {
        window.Telegram.WebApp.expand()
        fetchData()
    }, [])


    if(cards === null) return <Loader />
    /*if(me?.user?.sumsubVerified === false || !me?.user?.haveAccess) {
        if(me?.user?.sumsubVerified === false) {
            showNotification("Error","Go through verification", "error")  //toast.error("Go through verification")
        } else {
            showNotification("Error","You're on the wait list", "error")
            //toast.error()
        }
        return nav("/")
    }*/
    return (
        <>
        
        <TapBar active={"cards"} me={me}/>
        <MainButton text="New card" func={() => nav("/open/card")} myButton/>
        <List style={{paddingBottom:"176px"}}>
            
        {/*<Back />*/}
            
            <Section>
                <SectionHeader style={{paddingTop:"16px"}}>YOUR CARDS</SectionHeader>
                {cards && cards.length > 0 ? cards?.map(card => {
                    return (
                        <VibrateModule>
                    <Cell  after={<Text>{String(card.mask).slice(13).replace(" ", "")}</Text>} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} onClick={() => nav(`/card/${card.id}`)} description={Number(card.cardBalance).toFixed(2)+(card.brand === "Visa" ? "$" : "$")} >
                        {card.brand}
                    </Cell>
                    </VibrateModule>
                    )
                }) : <Placeholder style={{margin:"0 0 16px 0"}} description="Card list is empty"></Placeholder>}
            </Section>

        </List>
        </>
    )
}

export default Cards