import { Avatar, List, Placeholder, Text } from "@telegram-apps/telegram-ui"
import MainButton from "../helpers/mainbutton"
import WarnIMG from "./../assets/img/warn.png"


const ErrorPage = ({type = "pin"}) => {
    return (
        <> 
            <List className="full_w"> 
                <div></div>
                <Placeholder header={<Text style={{fontWeight:"700", fontSize:"32px"}}>Please, contact support</Text>}
                    description={<div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:"5px", flexDirection:"column"}}><Text className="ordinary__text">{type === "pin" ? "The account was locked after several unsuccessful attempts to enter the pin code." : "You have been denied verification. Contact support for details and to go through the verification procedure again"}</Text></div>}>
                    <Avatar size={64} src={WarnIMG} />
                </Placeholder>
                <div></div>
            </List>
            <MainButton text={"Open chat with support"} myButton withoutTabbar func={() => {
               window.open("https://t.me/bfinsupbot")
            }}/>
        </>
    )
}

export default ErrorPage