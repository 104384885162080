import SumsubWebSdk from "@sumsub/websdk-react";
import Back from "../helpers/back";
import { useEffect } from "react";
import ErrorPage from "../components/ErrorPage";
import Loader from "../helpers/loader";
import { useNavigate } from "react-router-dom";
import { Placeholder } from "@telegram-apps/telegram-ui";

import SeedImg from "./../assets/img/seed.png"
import MainButton from "../helpers/mainbutton";

const Kyc = ({iframeUrl,setAccessToken, me, setKyc}) => {

    const nav = useNavigate()

    if(me?.user?.isVerifyRejected) return <ErrorPage type="verif"/>
    if(!iframeUrl) return <Loader />
    return (
          <div style={{width:"100%", height:"100vh", display:'flex', alignItems:"center", justifyContent:"center"}}>
            <Back callback={() => {
              setAccessToken(null)
              setKyc(false)
            }}/>

            <MainButton text={"Proceed"} myButton withoutTabbar func={() => window.Telegram.WebApp.openLink(iframeUrl) }/>
            

            <Placeholder header="Verification required" description="To access this feature, you must complete identity verification. Please click button below to verify your identity. This process ensures the security and privacy of your account. If you encounter any issues, please contact our support team for assistance.">
                <img src={SeedImg} style={{width:"112px", height:"112px"}} />
            </Placeholder>

            {/*
                <iframe className="iframe" src={iframeUrl} />
                {/*<SumsubWebSdk
                    accessToken={accessToken}
                    updateAccessToken={() => console.log("updateAccessToken")}
                    expirationHandler={() => Promise.resolve(accessToken)}
                    config={{
                        lang: "ru-RU",
                        onMessage: (type, payload) => {
                            console.log("WebSDK onMessage", type, payload);
                        },
                        onError: (error) => {
                            console.error("WebSDK onError", error);
                        }
                    }}
                    options={{ addViewportTag: false, adaptIframeHeight: true }}
                    onMessage={(type, payload) => {
                        console.log("onMessage", type, payload);
                    }}
                    onError={(data) => console.log("onError", data)}
                />}

                */}
        </div>
    )
}

export default Kyc