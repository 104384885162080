import React from "react";

const VibrateModule = ({children}) => {
    const handleClick = (originalOnClick) => (event) => {
        // Вызов вибрации
        window.Telegram?.WebApp?.HapticFeedback?.impactOccurred(
            window.Telegram?.WebApp.platform === 'ios' ? 'rigid' : 'light'
        );

        // Вызов исходного onClick, если он был задан
        if (originalOnClick) {
            originalOnClick(event);
        }
    };

    return React.Children.map(children, child =>
        // Проверяем, является ли child валидным элементом React
        React.isValidElement(child)
            ? React.cloneElement(child, {
                  onClick: handleClick(child.props.onClick)
              })
            : child
    );
}

export default VibrateModule;
