import { Avatar, Button, Cell, Image, Input, List, Modal, Placeholder, Section, SegmentedControl, Spinner, Text } from "@telegram-apps/telegram-ui"
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem"
import { useEffect, useState } from "react"
import {getProductsByCountry, getProductsByGlobal, getProductsByRegion, posteSIM, postPhoneTopup} from "./../../../../../api/api"
import MasterCardImg from "../../../../../assets/img/mc.png"
import VisaImg from "../../../../../assets/img/vis.png"
import toast from "react-hot-toast"
import Back from "../../../../../helpers/back"
import Loader from "../../../../../helpers/loader"
import VibrateModule from "../../../../../helpers/vibrate"
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader"
import {Icon24ChevronRightOutline} from "@vkontakte/icons"
import TransferPage from "../../../transactions/transactionPages/transfer"
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close"
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader"
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose"
import MyESIMs from "./components/myESIMs"
import { useLocation } from "react-router-dom"
import { Map } from "./components/map"
import { useNotification } from "../../../../../helpers/notification"
import MyEsimsImg from "./../../../../../assets/img/transactions/Esim.png"

const EsimService = ({cards, loading, getSelectedService, step, setStep, setSelectedService, setLoading, countries}) => {
    const [selectedControl, setSelectedControl] = useState("Local")
    const [selectedCountry, setSelectedCountry] = useState(null)
    const [searchQuery, setSearchQuery] = useState("")
    const [popularCountry, setPopularCountry] = useState(null)
    const [filteredCountries, setFilteredCountries] = useState(null) 

    const [payment, setPayment] = useState(null)

    const [modalActive, setModalActive] = useState(false)

    const {showNotification} = useNotification()

    const [regions, setRegions] = useState([
        { name: "Africa", id:3 },
        { name: "Asia", id:1 },
        { name: "Caribbean Islands" , id:9},
        { name: "Europe", id:2 },
        { name: "South America" , id:7},
        { name: "Middle East", id:8 },
        { name: "North America" , id:6},
        { name: "Oceania", id:4 }
    ]);

    const selectPayment = async () => {
        setModalActive(true)
    }

    const handleChooseCountry = async (country) => {
        setSelectedCountry(country)
    }

    const filterCountries = (countries, query) => {
        return countries.filter(country =>
            country.name.toLowerCase().includes(String(query ? query : '').toLowerCase())
        );
    };

    const location = useLocation()

    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    
    useEffect(() => {
        setPopularCountry(countries.filter((country) => country.name === "Turkey" || country.name === "China" ||
         country.name === "Thailand"  || country.name === "United Arab Emirates"|| country.name === "Egypt"
          || country.name === "Germany"|| country.name === "Malaysia"  || country.name === "Kazakhstan"    ))
    }, [countries])

    useEffect(() => {
        const filteredCountries = filterCountries(countries.filter(country => country.esimId), searchQuery);
        setFilteredCountries(filteredCountries); 
    }, [searchQuery, countries]); 


    const [productCountry, setProductsCountry] = useState(null)
    const [selectedProductCountry, setSelectedProductsCountry] = useState(null)

    useEffect(() => {
        if(!selectedCountry) return
        async function getProducts() {
            const res = await getProductsByCountry(selectedCountry.isoName)
            if (res.status === "error") {
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setProductsCountry(res.data)
        }
        getProducts()
    }, [selectedCountry])


    const [selectedRegion, setSelectedRegion] = useState(null)

    const [networkCoverage, setNetworkCoverage] = useState([]);


    const generateRandomColor = () => {
        const randomColor = 'white';
        return randomColor;
      };

    const colorCountry = (isoCode, color) => {
        if(isoCode.length > 5) return
        const countryPath = document.querySelector(`svg #${isoCode}`);
        if (countryPath) {
          countryPath.style.fill = color;
        }
      };

    useEffect(() => {
        if(!selectedRegion) return
        async function getProducts() {
            const res = await getProductsByRegion(selectedRegion.id)
            if (res.status === "error") {
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setProductsCountry(res.data)

            const countryCodes = new Set();

            res.data.forEach(product => {
                product.countries.forEach(country => {
                    countryCodes.add(country.isoName);
                });
            });

            countryCodes.forEach(code => {
                const color = generateRandomColor();
                colorCountry(code, color);
            });
        }
        getProducts()
    }, [selectedRegion])

    const [selectedGlobal, setSelectedGlobal] = useState(null)

    useEffect(() => {
        if(selectedGlobal) {
            async function getProducts() {
                const res = await getProductsByGlobal()
                if (res.status === "error") {
                    return showNotification("Error",res.error.message, "error") //toast.error();
                }
                setProductsCountry(res.data)

                const countryCodes = new Set();

                res.data.forEach(product => {
                    product.countries.forEach(country => {
                        countryCodes.add(country.isoName);
                    });
                });

                countryCodes.forEach(code => {
                    const color = generateRandomColor();
                    colorCountry(code, color);
                });
                }
            getProducts()
        }
    }, [selectedGlobal])


    const [result, setResult] = useState(null)
    
    useEffect(() => {
        setProductsCountry(null)
    }, [selectedControl])

    async function handleSubmitOrder() {
        if(loading === true) return 
        setLoading(true)
        try {
            const res = await posteSIM({
                id: selectedProductCountry.id,
                payType: payment.type,
                payData: payment.value,
                type: selectedControl.toLowerCase() === 'regional' ? "region" : selectedControl.toLowerCase(),
                lData: selectedControl.toLowerCase() === 'local' ? selectedCountry.esimId : selectedControl.toLowerCase() === 'regional' ? selectedRegion.id   : ""

            });
            if (res.status === "error") {
                return showNotification("Error",res.error.message, "error") //toast.error();
            }
            setResult(res.data)
            return showNotification("Success","eSIM successfully purchased", "success") 
        } finally {
            setLoading(false)
        }
    }

    if(result) return <TransferPage handleClose={() => {
        setStep(2)
        setModalActive(false)
        setResult(null)
        setSelectedCountry(null)
        setSelectedRegion(null)
    }} transfer={{amount: Number(selectedProductCountry.price).toFixed(2) , cur: "USD" }} head={"eSIM"} info={"You have successfully completed the payment! Your eSIM will be ready in several minutes"}/>
    if(popularCountry === null || filteredCountries === null) return <Loader />
    return (
        <List>
            {step === 2 ?
                <>
                    {selectedCountry || selectedRegion  ? <>
                        {selectedControl === "Local" ? <>
                            <Back callback={() => {
                                setStep(2)
                                setSelectedCountry(null)
                                setProductsCountry(null)
                            }}/>
                            <>
                            <Placeholder header={selectedCountry.name} description={`eSIM`}>
                                <Avatar acronym={String(selectedCountry.name).charAt(0)} size={64} src={selectedCountry.flagUrl} />
                            </Placeholder>
                            <Section>
                                    <SectionHeader style={{paddingTop:"16px"}}>Available packages</SectionHeader>
                                    {productCountry === null ? <>
                                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                                            <Spinner />
                                        </div>
                                        

                                    </> : productCountry ? <>
                                        {productCountry.map((product) => {
                                            return (
                                                <VibrateModule>
                                                    <Cell 
                                                        style={{ margin: "2px 0" }}
                                                        description={String(product.name).toLowerCase().includes("unlimited") ? `${product.package_validity}  ${product.package_validity_unit}` : 

                                                            product.package_type === "DATA-ONLY" ? `${product.package_validity} ${product.package_validity_unit}` :

                                                            `${product.package_validity} ${product.package_validity_unit}` }
                                                        onClick={() => {
                                                            setStep(3)
                                                            setSelectedProductsCountry(product)
                                                        }}
                                                        after={<div style={{display:"flex", alignItems:"end", gap:"4px", whiteSpace:"nowrap", flexDirection:"column"}}>
                                                            <Text className="vkuiIcon">{Number(product.price).toFixed(2)}$</Text>
                                                            {String(product.name).toLowerCase().includes("unlimited") ? "" :  <Text>{Math.abs(Number(product.price) / Number(product.data_quantity)).toFixed(2)}$ per {product.data_unit}</Text>}
                                                        </div>}
                                                    >
                                                        {String(product.name).toLowerCase().includes("unlimited") ? "Unlimited" : 
                                                        product.package_type === "DATA-ONLY" ? `${product.data_quantity} ${product.data_unit}` :
                                                        `${product.data_quantity} ${product.data_unit}, ${product.voice_quantity} ${product.voice_unit}, ${product.sms_quantity} SMS` }
                                                    </Cell>
                                                </VibrateModule>
                                            )
                                        })}
                                    </> : <Placeholder style={{ margin: "14px 0" }} description={"Product list is empty"} />}
                                </Section>
                            </>
                        </> : selectedControl === "Regional" ?  <>
                            <Back callback={() => {
                                setStep(2)
                                setProductsCountry(null)
                                setSelectedRegion(null)
                            }}/>
                            <>
                            <Placeholder header={selectedRegion.name} description={`eSIM`}>
                            <Map />


                            </Placeholder>
                            <Section>
                                    <SectionHeader style={{paddingTop:"16px"}}>Available packages</SectionHeader>
                                    {productCountry === null ? <>
                                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                                            <Spinner />
                                        </div>
                                        

                                    </> : productCountry ? <>
                                        {productCountry.map((product) => {
                                            return (
                                                <VibrateModule>
                                                    <Cell 
                                                        style={{ margin: "2px 0" }}
                                                        description={String(product.name).toLowerCase().includes("unlimited") ? `${product.package_validity}  ${product.package_validity_unit}` : 

                                                            product.package_type === "DATA-ONLY" ? `${product.package_validity} ${product.package_validity_unit}` :

                                                            `${product.package_validity} ${product.package_validity_unit}` }
                                                        onClick={() => {
                                                            setStep(3)
                                                            setSelectedProductsCountry(product)
                                                        }}
                                                        after={<div style={{display:"flex", alignItems:"end", gap:"4px", whiteSpace:"nowrap", flexDirection:"column"}}>
                                                            <Text className="vkuiIcon">{Number(product.price).toFixed(2)}$</Text>
                                                            {String(product.name).toLowerCase().includes("unlimited") ? "" :  <Text>{Math.abs(Number(product.price) / Number(product.data_quantity)).toFixed(2)}$ per {product.data_unit}</Text>}
                                                        </div>}
                                                    >
                                                        {String(product.name).toLowerCase().includes("unlimited") ? "Unlimited" : 
                                                        product.package_type === "DATA-ONLY" ? `${product.data_quantity} ${product.data_unit}` :
                                                        `${product.data_quantity} ${product.data_unit}, ${product.voice_quantity} ${product.voice_unit}, ${product.sms_quantity} SMS` }
                                                    </Cell>
                                                </VibrateModule>
                                            )
                                        })}
                                    </> : <Placeholder style={{ margin: "14px 0" }} description={"Package list is empty"} />}
                                </Section>
                            </>
                        </> : <></>}
                    </> : <>
                    <Back callback={() => {
                        setStep(1)
                        setSelectedService(null)
                    }}/>
                    <Section header={"ESIMs"} className="tabs_block">
                        <SegmentedControl className="tabs">
                            <SegmentedControlItem
                                onClick={() => {
                                    setSelectedGlobal(false)
                                    setSelectedControl("Local")
                                }}
                                selected={selectedControl === "Local"}
                            >
                                Local
                            </SegmentedControlItem>
                            <SegmentedControlItem
                                onClick={() => {
                                    setSelectedGlobal(false)
                                    setSelectedControl("Regional")
                                }}
                                selected={selectedControl === "Regional"}
                            >
                                Regional
                            </SegmentedControlItem>
                            <SegmentedControlItem
                                onClick={() => {
                                    setSelectedGlobal(true)
                                    setSelectedControl("Global")
                                }}
                                selected={selectedControl === "Global"}
                            >
                                Global
                            </SegmentedControlItem>
                        </SegmentedControl>
                    </Section>

                    <Section>
                        <Cell onClick={() => setStep(5)} before={<Image size={32} src="/img/myesims.png"/>}>
                            My eSIMs
                        </Cell>
                        <Cell onClick={() => {
                            window.open("https://guide.bfinance.app")
                        }}before={<Image size={32} src="/img/faq.png"/>}>
                            FAQ
                        </Cell>
                    </Section>

                    {selectedControl === "Local" ? <>
                        <Section header={"Popular countries"}> </Section>
                            {popularCountry.length > 0 ? popularCountry.map((country, index) => (
                                <VibrateModule key={index}>
                                    <Section>
                                        <Cell
                                            style={{ margin: "2px 0" }}
                                            before={<Avatar acronym={String(country.name).charAt(0)} size={28} src={country.flagUrl} />}
                                            onClick={() => handleChooseCountry(country)}
                                        >
                                            {country.name}
                                        </Cell>
                                    </Section>
                                </VibrateModule>
                            )) : <Placeholder style={{ margin: "14px 0" }} description={"Country list is empty"} />}
                        

                        <Section header={"All countries"}></Section>
                            <Input
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                placeholder="Search country"
                                className="input"
                            />
                            {filteredCountries.length > 0 ? filteredCountries.map((country, index) => (
                                <VibrateModule key={index}>
                                    <Section>
                                        <Cell
                                            style={{ margin: "2px 0" }}
                                            before={<Avatar acronym={String(country.name).charAt(0)} size={28} src={country.flagUrl} />}
                                            onClick={() => handleChooseCountry(country)}
                                        >
                                            {country.name}
                                        </Cell>
                                    </Section>
                                </VibrateModule>
                            )) : <Placeholder style={{ margin: "14px 0" }} description={"Country list is empty"} />}
                        
                    </> : selectedControl === "Regional" ? <>
                        <Section header="All regions"></Section>
                            {regions.length > 0 ? regions.map((country, index) => (
                                <VibrateModule key={index}>
                                    <Section>
                                        <Cell
                                            onClick={() => setSelectedRegion(country)}
                                        >
                                            {country.name}
                                        </Cell>
                                    </Section>
                                </VibrateModule>
                            )) : <Placeholder style={{ margin: "14px 0" }} description={"Country list is empty"} />}
                    </> : selectedControl === "Global" ? <>
                            <Back callback={() => {
                                setStep(1)
                                setSelectedGlobal(false)
                                setSelectedService(null)
                            }}/>
                            <>
                            <Placeholder header={"Global"} description={`eSIM`}> 

                            <Map />
                            </Placeholder>
                            <Section>
                                    <SectionHeader style={{paddingTop:"16px"}}>Available packages</SectionHeader>
                                    {productCountry === null ? <>
                                        <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                                            <Spinner />
                                        </div>
                                        

                                    </> : productCountry ? <>
                                        {productCountry.map((product) => {
                                            return (
                                                <VibrateModule>
                                                    <Cell 
                                                        style={{ margin: "2px 0" }}
                                                        description={String(product.name).toLowerCase().includes("unlimited") ? `${product.package_validity}  ${product.package_validity_unit}` : 

                                                            product.package_type === "DATA-ONLY" ? `${product.package_validity} ${product.package_validity_unit}` :

                                                            `${product.package_validity} ${product.package_validity_unit}` }
                                                        onClick={() => {
                                                            setStep(3)
                                                            setSelectedProductsCountry(product)
                                                        }}
                                                        after={<div style={{display:"flex", alignItems:"end", gap:"4px", whiteSpace:"nowrap", flexDirection:"column"}}>
                                                            <Text className="vkuiIcon">{Number(product.price).toFixed(2)}$</Text>
                                                            {String(product.name).toLowerCase().includes("unlimited") ? "" : <Text>{Math.abs(Number(product.price) / Number(product.data_quantity)).toFixed(2)}$ per {product.data_unit}</Text>}
                                                        </div>}
                                                    >
                                                        {String(product.name).toLowerCase().includes("unlimited") ? "Unlimited" : 
                                                        product.package_type === "DATA-ONLY" ? `${product.data_quantity} ${product.data_unit}` :
                                                        `${product.data_quantity} ${product.data_unit}, ${product.voice_quantity} ${product.voice_unit}, ${product.sms_quantity} SMS` }
                                                    </Cell>
                                                </VibrateModule>
                                            )
                                        })}
                                    </> : <Placeholder style={{ margin: "14px 0" }} description={"Product list is empty"} />}
                                </Section>
                            </>
                        </> : <></> }
                    </>}
                </>
            : step === 3 ? <>

            <Back callback={() => {
                setStep(2)
                setSelectedProductsCountry(null)
                setModalActive(false)
            }}/>
             <Section>
                <SectionHeader style={{paddingTop:"16px"}}>Checkout</SectionHeader>
                <Cell 
                    style={{ margin: "2px 0" }}
                    description={String(selectedProductCountry.name).toLowerCase().includes("unlimited") ? `${selectedProductCountry.package_validity}  ${selectedProductCountry.package_validity_unit}` : 

                    selectedProductCountry.package_type === "DATA-ONLY" ? `${selectedProductCountry.package_validity} ${selectedProductCountry.package_validity_unit}` :

                        `${selectedProductCountry.package_validity} ${selectedProductCountry.package_validity_unit}` }
                    after={<div style={{display:"flex", alignItems:"end", gap:"4px", whiteSpace:"nowrap", flexDirection:"column"}}>
                        <Text className="vkuiIcon">{Number(selectedProductCountry.price).toFixed(2)}$</Text>
                        {String(selectedProductCountry.name).toLowerCase().includes("unlimited") ? "" :  <Text>{Math.abs(Number(selectedProductCountry.price) / Number(selectedProductCountry.data_quantity)).toFixed(2)}$ per {selectedProductCountry.data_unit}</Text>}
                    </div>}
                >
                    {String(selectedProductCountry.name).toLowerCase().includes("unlimited") ? "Unlimited" : 
                    selectedProductCountry.package_type === "DATA-ONLY" ? `${selectedProductCountry.data_quantity} ${selectedProductCountry.data_unit}` :
                    `${selectedProductCountry.data_quantity} ${selectedProductCountry.data_unit}, ${selectedProductCountry.voice_quantity} ${selectedProductCountry.voice_unit}, ${selectedProductCountry.sms_quantity} SMS` }
                </Cell>
                {selectedProductCountry.countries && <Cell onClick={() => setStep(4)} after={<Icon24ChevronRightOutline className="right__arrow" width={16} height={16} />}>
                    Available countries
                </Cell>}
             </Section>
             <Section header="payment method">
                        {/*<VibrateModule>
                        <Cell subhead="Crypto" onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "ton"
                            })
                        }} before={<Avatar size={48} src={'/img/ton.png'}/>}>
                            TON
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    <Section >
                    <VibrateModule>
                        <Cell onClick={() => {
                            selectPayment()
                            setPayment({
                                type: "crypto",
                                value: "usdt"
                            })
                        }} subhead="Crypto" before={<Avatar size={48} src={'/img/usdt.png'}/>}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                    {cards.length > 0 ? cards?.map(card => {
                        return (
                            <Section>
                                <VibrateModule>
                                <Cell disabled subhead={"Card"} before={<Avatar size={48} src={String(card.brand).toLowerCase() === "mastercard" ? MasterCardImg : String(card.brand).toLowerCase() === "visa" ? VisaImg  : ""} acronym={card.brand.charAt(0)} />} 
                                onClick={() => {
                                    return
                                    selectPayment()
                                    setPayment({
                                        type: "card",
                                        value: card.id
                                    })
                                }}>
                                    {card.brand} {String(card.mask).slice(13).replace(" ", "")}
                                </Cell>
                                </VibrateModule>
                            </Section>
                        )
                    }) : "" }
            </> : step === 4 ?
            <>
                <Back callback={() => {
                    setStep(3)
                }}/>
                
                <Section header="AVAILABLE COUNTRIES">
                            {selectedProductCountry.countries ? selectedProductCountry.countries.map(country => {
                                return (
                                <Cell 
                                description={
                                    [
                                        country.network_coverage.some(network => network.two_g) ? "2G" : null,
                                        country.network_coverage.some(network => network.three_g) ? "3G" : null,
                                        country.network_coverage.some(network => network.four_G) ? "4G" : null,
                                        country.network_coverage.some(network => network.five_G) ? "5G" : null
                                    ]
                                    .filter(Boolean) // Remove null values
                                    .join(", ") // Join the values with a comma and space
                                }
                                 
                                subhead={
                                    country.network_coverage.length === 0 ? "" : `${country.network_coverage[0].network_name} ${country.network_coverage.length > 1 ? ", +"+String(country.network_coverage.length - 1) : ""}` 
                                } onClick={() => {
                                }} before={<Avatar size={28} src={country.image_url}/>}>
                                    {country.name}
                                </Cell>
                                )
                            }) : <Placeholder style={{ margin: "14px 0" }} description={"Avalible countries not found"} />}
                            
                </Section></>
                 : step === 5 ? <MyESIMs setStep={setStep} step={step} /> :  <Loader />}
            
            <Modal
                open={modalActive}
                header={<ModalHeader after={<ModalClose onClick={() => {
                    setModalActive(false)
                }}><Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} /></ModalClose>}>Confirm payment</ModalHeader>}
                dismissible={false}
                >
                <Placeholder
                    description={`You want to buy ${selectedProductCountry?.name}`}
                    header="Are you sure?"
                    action={<Button onClick={() => {
                        handleSubmitOrder()
                    }} stretched loading={loading}>Confirm</Button>}
                >
                </Placeholder>
            </Modal>
        </List>
    )
}

export default EsimService
