import { Avatar, Badge, Banner, Button, Cell, Image, InlineButtons, List, Modal, Placeholder, Section, Spinner, Text, Title } from "@telegram-apps/telegram-ui";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCards, getHistory, getTasks, postKyc, postResolveQr } from "../api/api";
import Loader from "../helpers/loader";
import Transaction from "./transaction";
import splitNumbers from "./../helpers/splitNumbers";
import DollarsImg from "./../assets/img/dollars.png"
import GiftImg from "./../assets/img/gift.png"
import TransactionData from "./components/transactions/transaction";
import InlineButtonsComponent from "./components/main/inlineButtons";
import VibrateModule from "../helpers/vibrate";
import { Icon16CheckOutline, Icon28ScanViewfinderOutline } from "@vkontakte/icons";
import Earn from "./components/main/components/earn";
import Banners from "./components/main/components/banners";
import TapBar from "./components/tabbar/tabbar";
import { useNotification } from "../helpers/notification";
import { ModalHeader } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalHeader/ModalHeader";
import { ModalClose } from "@telegram-apps/telegram-ui/dist/components/Overlays/Modal/components/ModalClose/ModalClose";
import { Icon28Close } from "@telegram-apps/telegram-ui/dist/icons/28/close";


import VisaImgG from "./../assets/img/visa.png"
import MasterCardImgG from "./../assets/img/mc_g.png"

const Main = ({ me, rates, fetchDataKyc, fetchMe }) => {
    const nav = useNavigate();
    const [cards, setCards] = useState(null);
    const [history, setHistory] = useState(null)   
    const [showAllBalances,setShowAllBalances] = useState(false)
    
    const [showQr,setShowQr] = useState(false)

    const [modalBonuses, setModalBonuses] = useState(false)

    

    const {showNotification} = useNotification()

    /*async function fetchDataHistory() {
        const res = await getHistory();
        if (res.status === "error") {
            return showNotification("Error", res.error.message, "error") //toast.error(res.error.message);
        }
        setHistory(res.data);
    }*/

    

    async function fetchData() {
        const res = await getCards();
        if (res.status === "error") {
            return showNotification("Error", res.error.message, "error")
        }
        setCards(res.data);
    }


    const handleQrCheck = async (data) => {
        const res = await postResolveQr(data)
        if (res.status === "error") {
            window?.Telegram?.WebApp?.closeScanQrPopup()
            return showNotification("Error", res.error.message, "error")
        }

        if (me?.user?.sumsubVerified === false /*|| !me?.user?.haveAccess*/) {
            if (me?.user?.sumsubVerified === false) {
                showNotification("Error", "Go through verification", "error");
            } /*else {
                showNotification("Error", "You're on the wait list", "error");
            }*/
        }

        if(res.data.page === "withdraw") {
            nav(`/withdraw/${res.data.data.currency ? `?selectedCur=${String(res.data.data.currency).toLowerCase()}` : ""}${res.data.data.data.address ? `&address=${res.data.data.data.address}` : ""}${res.data.data.data.amount ? `&amount=${res.data.data.data.address}` : ""}`)
        } else if (res.data.page === "payment") {
            nav(`/payment/${res.data.id}`)
        }

    }

    const openQrScanner = () => {
        const handleQrScan = (data) => {
            handleQrCheck(data); 
            return true
        };

        window.Telegram.WebApp.showScanQrPopup({}, handleQrScan);
    };

    useEffect(() => {
        const handleQrPopupClosed = () => {

        };

        window.Telegram.WebApp.onEvent('scanQrPopupClosed', handleQrPopupClosed);

        return () => {
            window.Telegram.WebApp.offEvent('scanQrPopupClosed', handleQrPopupClosed);
        };
    }, []);
    
    

    useEffect(() => {
        window.Telegram.WebApp.expand();
        fetchData();
        //fetchDataHistory();
    }, []);

    const location = useLocation()

    const getQueryParams = () => {
        return new URLSearchParams(location.search);
    };

    const esim = getQueryParams().get("tgWebAppStartParam")
    if(String(esim).includes("esim_")) {
        return nav(`/online/payments?tgWebAppStartParam=${esim}`)
    } 


    const getDollarValue = (balance, rate) => (balance ? (Number(balance) * Number(rate)).toFixed(2) : 0);

    const sortedBalances = [
        /*{ id: 'ton', name: 'Toncoin', balance: me?.user?.balances?.ton, rate: rates?.ton?.usd, imgSrc: '/img/ton.png' },
        { id: 'trx', name: 'TRON', balance: me?.user?.balances?.trx, rate: rates?.trx?.usd, imgSrc: '/img/trx.png' },
        { id: 'bnb', name: 'BNB', balance: me?.user?.balances?.bnb, rate: rates?.bnb?.usd, imgSrc: '/img/bnb.png'}, */
        { id: 'usd', name: 'Dollars', balance: me?.user?.balances?.usd, rate: 1, imgSrc: DollarsImg/* '/img/usdt.png'*/ },
        //{ id: 'bbx', name: 'BITBIX', balance: me?.user?.balances?.bbx, rate: 1, imgSrc: '/img/bbx.png' },
        { id: 'usd ', name: 'Bonus balance', balance: me?.user?.balances?.bonus, rate: 1, imgSrc: GiftImg }
    ].map(item => ({
        ...item,
        dollarValue: getDollarValue(item.balance, item.rate)
    })).sort((a, b) => b.dollarValue - a.dollarValue);

    const displayedBalances = showAllBalances ? sortedBalances : sortedBalances.slice(0, 2);
    
    if (cards === null) return <Loader />;

    //if(showQr) return <QrPopup me={me} />
    return (
        <>
            {/*<QrPopup me={me} />*/}
            <div style={{paddingBottom:"76px"}}>
            <TapBar active={"main"} me={me} fetchDataKyc={fetchDataKyc}/>
            
                <>
                    {/*<Placeholder
                        header={<div className="balance__block__amount">
                            <Text className="balance__block__bal" style={{fontSize: splitNumbers(Number(me?.user?.totalBalance).toFixed(2)).length > 5 ? "36px" : "46px"}}><span>$</span>{me?.user?.totalBalance ? splitNumbers(Number(me?.user?.totalBalance).toFixed(2)) : 0}</Text>
                            <VibrateModule><svg onClick={() => {
                                //if(!me?.user?.haveAccess) return toast.error("You're on the wait list")
                                nav("/topup")
                            }}  width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" className="icon_svg" clip-rule="evenodd" d="M12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5C1 18.5751 5.92487 23.5 12 23.5ZM12.9 7.5C12.9 7.00294 12.4971 6.6 12 6.6C11.5029 6.6 11.1 7.00294 11.1 7.5V11.6H7C6.50294 11.6 6.1 12.0029 6.1 12.5C6.1 12.9971 6.50294 13.4 7 13.4H11.1V17.5C11.1 17.9971 11.5029 18.4 12 18.4C12.4971 18.4 12.9 17.9971 12.9 17.5V13.4H17C17.4971 13.4 17.9 12.9971 17.9 12.5C17.9 12.0029 17.4971 11.6 17 11.6H12.9V7.5Z" fill="none"/>
                                </svg>
                            </VibrateModule>
                        </div>}
                    >
                        <Text className="balance__block__title">Total Balance</Text>
                    </Placeholder>*/}
                    <List style={{paddingTop:"24px", position:"relative"}}>
                        {me?.user?.sumsubVerified === true && /*me?.user?.haveAccess*/ true && <Icon28ScanViewfinderOutline onClick={() => {
                            openQrScanner()
                        }} width={28} height={28} className="scan__icon"/>}
                        <Text className="hint" style={{display:"block"}}>Total balance</Text>
                        <Text className="balance__block__bal" style={{lineHeight:"40px",fontSize: splitNumbers(Number(me?.user?.totalBalance).toFixed(2)).length > 5 ? "36px" : "46px"}}><span>$</span>{me?.user?.totalBalance ? splitNumbers(Number(me?.user?.totalBalance).toFixed(2)) : 0}</Text>
                    </List>
                    
                    <List><InlineButtonsComponent me={me} fetchDataKyc={fetchDataKyc}/></List>
                    

                    <List>
                        <Section header="CRYPTO" style={{marginBottom:"0px"}}></Section>
                        <div className="cards__info__block">
                            {displayedBalances.map((bal) => {
                                return (
                                    <VibrateModule>
                                    <Cell onClick={() => {
                                        if(bal.name === "Bonus balance") {
                                            return setModalBonuses(true)
                                        }
                                        nav("/balance/"+bal.id)
                                    }} subtitle={`${bal.balance ? Number(bal.balance).toFixed(2) : 0} ${String(bal.id === "usd" ? "usdt" : bal.id).toUpperCase()}`} after={<Text>{bal.balance ? splitNumbers((Number(bal.balance) * Number(bal.rate)).toFixed(2)) : 0}{bal.name === "BITBIX" ? " BBX" : "$"}</Text>} before={<Avatar size={40} src={bal.imgSrc}/>}>
                                        {bal.name}
                                    </Cell>
                                    </VibrateModule>
                                )
                            })}

                        </div>
                        {/*!showAllBalances && <Button onClick={() => setShowAllBalances(true)} stretched>Show other tokens</Button>*/}
                    </List>

                    {cards && cards.length > 0 && <List>
                        <Section header="CARDS" style={{marginBottom:"0px"}}></Section>
                        <div className="cards__info__block">
                            {cards.map((card) => {
                                return (
                                    <VibrateModule>
                                        <Cell 
                                        onClick={() => {
                                            nav("/card/"+card.id)
                                        }} 
                                        subtitle={`${card.cardBalance ? Number(card.cardBalance).toFixed(2) : 0} $`} 
                                        after={<Text>{String(card.mask).slice(13).replace(" ", "")}</Text>} 
                                        before={<Avatar size={40} src={String(card.brand).toLowerCase() == "visa" ? VisaImgG : MasterCardImgG }/>}>
                                            {card.brand}
                                        </Cell>
                                    </VibrateModule>
                                )
                            })}
                        </div>
                    </List>}

                    <Banners fetchDataKyc={fetchDataKyc} cards={cards} me={me}/>

                    {/* ? 
                            <Earn me={me} fetchMe={fetchMe}/>
                        : <List style={{padding:"8px 18px"}} className="transactions">
                            <Section >
                                <SectionHeader style={{ paddingTop: "16px" }}>TRANSACTION HISTORY</SectionHeader>
                                {history !== null ?history?.length > 0 ? <>
                                    {history.map((transaction, index) => {
                                        return (
                                            <VibrateModule>
                                            <TransactionData handleTransactionClick={handleTransactionClick} transaction={transaction} index={index}/>
                                            </VibrateModule>
                                        );
                                    })}
                                </> : <Placeholder style={{ margin: "0 0 16px 0" }} description="Transaction list is empty"></Placeholder> :  <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                                 <Spinner />
                             </div>}
                            </Section>
                        </List>
                    */}
                    
                </>
        </div>

        <Modal
            open={modalBonuses}
            header={<ModalHeader after={<ModalClose onClick={() => {
                setModalBonuses(false)
            }}><Icon28Close style={{color: 'var(--tgui--plain_foreground)'}} /></ModalClose>}>Bonus balance</ModalHeader>}
            dismissible={false}
            >
            <Placeholder
                header="You can only use the bonus balance to create a card and recharge it."
            >
            </Placeholder>
        </Modal>
        </>
    );
};

export default Main;


/*

                            
                            <Cell subtitle={`${Number(me?.user?.balances?.usd).toFixed(2)} USDT`} onClick={() => nav("/balance/usdt")} after={<Text>${`${Number(me?.user?.balances?.usd).toFixed(2)}`}</Text>} before={<Avatar src="/img/usdt.png" />}>
                                Tether USD
                            </Cell>
                            <Cell onClick={() => nav("/balance/trx")} after={<Text>${(Number(me?.user?.balances?.trx) * Number(rates?.trx?.usd)).toFixed(2)}</Text>}  subtitle={`${me?.user?.balances?.trx ? Number(me?.user?.balances?.trx).toFixed(2) : 0} TRON`} before={<Avatar src="/img/trx.png" />}>
                                TRON
                            </Cell>
                            <Cell onClick={() => nav("/balance/bnb")} after={<Text>${(Number(me?.user?.balances?.bnb) * Number(rates?.bnb?.usd)).toFixed(2)}</Text>} subtitle={`${me?.user?.balances?.bnb ? Number(me?.user?.balances?.bnb).toFixed(6) : 0} BNB`} before={<Avatar src="/img/bnb.png" />}>
                                BNB
                            </Cell>
                            <Cell onClick={() => nav("/balance/bbx")} after={<Text>${(Number(me?.user?.balances?.ton) * Number(rates?.bbx?.usd)).toFixed(2)}</Text>} subtitle={`${me?.user?.balances?.bbx ? Number(me?.user?.balances?.bbx).toFixed(2) : 0} BBX`} before={<Avatar src="/img/bbx.png" />}>
                                BITBIX
                            </Cell>
                            */