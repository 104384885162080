import { Cell, List, Section } from "@telegram-apps/telegram-ui";
import VibrateModule from "../../../helpers/vibrate";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import Back from "../../../helpers/back";

export const LimitsData = ({ me, setLimitsDataStatus }) => {
    return (
        <>
            <Back callback={() => setLimitsDataStatus(false)} />
            <List>
                <Section>
                    <SectionHeader style={{ paddingTop: "16px" }}>
                    WALLET
                    </SectionHeader>
                    <VibrateModule>
                        <Cell description="Top-up fee">
                        0%
                        </Cell>
                        <Cell description="Withdraw fee">
                        0.3 TON / 1.5 USDT
                        </Cell>
                    </VibrateModule>
                </Section>
                <Section>
                    <SectionHeader style={{ paddingTop: "16px" }}>
                    bank account
                    </SectionHeader>
                    <VibrateModule>
                        <Cell description="Convertation fee">
                            1%
                        </Cell>
                    </VibrateModule>
                </Section>
                <Section>
                    <SectionHeader style={{ paddingTop: "16px" }}>
                    CARD
                    </SectionHeader>
                    <VibrateModule>
                        <Cell description="Top-up fee">
                        4%
                        </Cell>
                        <Cell description="Transaction fee (USD)">
                        0,4% + 0.25$
                        </Cell>
                        <Cell description="Transaction fee (not USD)">
                        1% + 0.25$
                        </Cell>
                        <Cell description="Declined fee">
                        0.25$
                        </Cell>
                    </VibrateModule>
                </Section>
            </List>
        </>
    );
};
