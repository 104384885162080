import { useState, useEffect } from "react";
import { fetchData } from "../../../api/api";

const fetchHistory = (currency = null) => {
  if (currency) {
    return fetchData("GET", `/api/me/history?currency=${currency}`);
  } else {
    return fetchData("GET", "/api/me/history");
  }
};

export { fetchHistory };
