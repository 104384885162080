import { Spinner } from "@telegram-apps/telegram-ui";
import { Player } from '@lottiefiles/react-lottie-player';
import whiteAnimation from './../assets/lottie/dark1.json'

const Loader = () => {

    return (
        <div style={{width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", position:"absolute",top:"0", left:"0", right:"0", left:"0"}}>
            <Player
                src={whiteAnimation}
                className="player"
                loop
                style={{width:"60px", height:"60px", borderRadius:"16px"}}
                autoplay
            />
            {/*<Spinner />*/}
        </div>
    );
};

export default Loader;
