import "./Account.css"
import TapBar from "../components/tabbar/tabbar"
import { Avatar, Button, Cell, Chip, Image, Input, List, Placeholder, Section, Text } from "@telegram-apps/telegram-ui"
import Back from "../../helpers/back"
import { postKyc } from "../../api/api"
import { useState } from "react"
import toast from "react-hot-toast"
import { PersonalData } from "./components/PersonalData"
import { SecurityData } from "./components/SecurityData"
import { LimitsData } from "./components/LimitsData"
import PrivacyPolicyData from "./components/PrivacyPolicyData"

import PrivacyImg from "./../../assets/img/privacy.png"
import { useNotification } from "../../helpers/notification"
import { useNavigate } from "react-router-dom"

const Account = ({me, fetchDataKyc, setMe}) => {
    const nav = useNavigate()

    const [personalDataStatus, setPersonalDataStatus] = useState(false)
    const [securityDataStatus, setSecurityDataStatus] = useState(false)
    const [limitsDataStatus, setLimitsDataStatus] = useState(false)
    const [policyStatus, setPolisyStatus] = useState(false)

    const { showNotification } = useNotification();


    if(personalDataStatus) return <PersonalData me={me} setMe={setMe} setPersonalDataStatus={setPersonalDataStatus}/>
    if(securityDataStatus) return <SecurityData me={me} setMe={setMe} setSecurityDataStatus={setSecurityDataStatus}/>
    if(limitsDataStatus) return <LimitsData me={me} setMe={setMe} setLimitsDataStatus={setLimitsDataStatus} />
    if(policyStatus) return <PrivacyPolicyData me={me} setMe={setMe} setPolisyStatus={setPolisyStatus} />
    return (
        <>
            <TapBar active={"account"} me={me} />
            <List style={{paddingBottom:"86px"}}>
                <Placeholder style={{gap:"16px"}} header={<Text className="account__name">{me.user.name}</Text>} action={
                    <div style={{display:'flex', alignItems:"center", flexDirection:"column"}}>
                        <Chip className={me.user.sumsubVerified ? "account__verified" : "account__chip"}>{me.user.sumsubVerified ? "Verified" : "Not verified"}</Chip>
                        {me.user.sumsubVerified ? <Text className="hint" style={{fontSize:"11px", marginTop:"5px"}}>LVL 1</Text> : ""}
                    </div>
                }>
                    <Avatar src={me.user.photoB64} size={64} acronym={String(me.user.name).charAt(0)} />
                </Placeholder>
                <Section>
                    <Cell onClick={() => {
                        //if (!me?.user?.haveAccess) return showNotification("Error", "You're on the wait list", "error");
                        if(Number(me?.user?.balances?.usd) <= 0) return showNotification("Error", "You need to top-up your balance", "error"); 
                        if(!me.user.sumsubVerified) return fetchDataKyc()
                        return setPersonalDataStatus(true)
                    }} before={<Image style={{borderRadius:"8px"}} size={32} src="/account/personal.png" /> }>
                        {me.user.sumsubVerified ? "Personal data" : "Identity verification"}
                    </Cell>
                    <Cell onClick={() => nav("/earn")} before={<Image style={{borderRadius:"8px"}}  size={32} src="/account/refprog.svg"/>}>
                        Affiliate program
                    </Cell>
                    <Cell onClick={() => setLimitsDataStatus(true)} before={<Image style={{borderRadius:"8px"}}  size={32} src="/account/currency.png"/>}>
                        Limits & fees
                    </Cell>
                </Section>
                <Section>
                    <Cell onClick={() => setSecurityDataStatus(true)} before={<Image style={{borderRadius:"8px"}}  size={32} src="/account/security.png"/>}>
                        Security
                    </Cell>
                </Section>
                <Section>
                    <Cell onClick={() => {
                        window.open("https://guide.bfinance.app")
                    }} before={<Image style={{borderRadius:"8px"}}  size={32} src="/account/features.png"/>}>
                        Guide
                    </Cell>
                    <Cell onClick={() => {
                        window.open("https://t.me/bfinsupbot")
                    }} before={<Image style={{borderRadius:"8px"}}  size={32} src="/account/support.png"/>}>
                        Support
                    </Cell>
                </Section>

                <Section>
                    <Cell onClick={() => {
                        setPolisyStatus(true)
                    }} before={<Image style={{borderRadius:"8px"}}  size={32} src={PrivacyImg}/>}>
                        Privacy Policy
                    </Cell>
                </Section>
            </List>


            
        </>
    )
}

export default Account