import { HistoryList } from "../../modules/HistoryList"
import TapBar from "../components/tabbar/tabbar"


const HistoryPage = ({me}) => {
    return (
        <>
            <TapBar active={"history"} me={me}/>
            <HistoryList />
        </>
        
    )
}


export default HistoryPage