import { Avatar, Divider, InlineButtons } from "@telegram-apps/telegram-ui"
import VibrateModule from "../../../helpers/vibrate"
import { InlineButtonsItem } from "@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem"
import { Icon20HomeOutline, Icon24HistoryBackwardOutline, Icon24PaymentCardOutline, Icon28MoneySendOutline } from "@vkontakte/icons"
import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import {usePlatform} from "@telegram-apps/telegram-ui/dist/hooks/usePlatform"
import { useNotification } from "../../../helpers/notification"



const TapBar = ({active, me, fetchDataKyc}) => {
    const {showNotification} = useNotification()
    const platform = usePlatform()
    const nav = useNavigate()
    return (
        <div className="tabbar" style={{paddingBottom: platform === "ios" || platform === "i" ? "16px" : ""}}>
            <Divider />
            <InlineButtons>
                <VibrateModule>
                    <InlineButtonsItem className={active === "main" ? "tabbar_active" : "tabbar_inert"} text="Home" onClick={() => {
                            nav("/")
                        }}>
                        <Icon20HomeOutline  width={28} height={28}/>

                    </InlineButtonsItem>
                </VibrateModule>
            
                <VibrateModule>
                <InlineButtonsItem className={active === "history" ? "tabbar_active" : "tabbar_inert"} text="History" onClick={() => {
                    //if(!me?.user?.haveAccess) return showNotification("Error","You're on the wait list", "error") 
                    //if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                    nav("/history")
                    }}>
                    <Icon24HistoryBackwardOutline  width={28} height={28}/>

                </InlineButtonsItem>
                </VibrateModule>

                <VibrateModule>
                    <InlineButtonsItem className={active === "payments" ? "tabbar_active" : "tabbar_inert"} text="Payments" onClick={() => {
                        //if(!me?.user?.haveAccess) return showNotification("Error","You're on the wait list", "error") 
                        //if(me?.user?.sumsubVerified === false) return fetchDataKyc()
                        nav("/online/payments")
                        }}>
                <Icon28MoneySendOutline width={28} height={28} />
                    </InlineButtonsItem>
                </VibrateModule>

                <VibrateModule>
                    <InlineButtonsItem className={active === "account" ? "tabbar_active" : "tabbar_inert"} text="Account" onClick={() => {
                        nav("/account")
                    }}>
                        <Avatar src={me.user.photoB64} size={28} acronym={String(me.user.name).charAt(0)} />
                    </InlineButtonsItem>
                </VibrateModule>
            </InlineButtons>
        </div>
    )
}

export default TapBar