import { Avatar, Cell, List, Placeholder, Section, SegmentedControl, Spinner } from "@telegram-apps/telegram-ui"
import TransactionData from "../../components/transactions/transaction"
import { SegmentedControlItem } from "@telegram-apps/telegram-ui/dist/components/Navigation/SegmentedControl/components/SegmentedControlItem/SegmentedControlItem"
import { useState } from "react"
import VibrateModule from "../../../helpers/vibrate"

import FreezeImg from "./../../../assets/img/transactions/Freeze_card.png"
import WithdrawImg from "./../../../assets/img/transactions/Withdraw.png"
import TopupImg from "./../../../assets/img/transactions/Topup.png"
import DownloadImg from "./../../../assets/img/transactions/Download.png"
import { useNotification } from "../../../helpers/notification"
import { useNavigate } from "react-router-dom"


const CardControllers = ({handleTransactionClick, transactions, datails, setFreezeModal, setStatusTopup}) => {
    const [selectedControl, setSelectedControl] = useState("Transactions")

    const { showNotification } = useNotification();
    const nav = useNavigate()
    return (
        <List>
            <>
                <Section className="tabs_block">
                    <SegmentedControl className="tabs">
                        <SegmentedControlItem
                            onClick={() => setSelectedControl("Transactions")}
                            selected={selectedControl === "Transactions"}
                        >
                            Transactions
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => setSelectedControl("Actions")}
                            selected={selectedControl === "Actions"}
                        >
                            Actions
                        </SegmentedControlItem>
                        <SegmentedControlItem
                            onClick={() => {
                                setSelectedControl("Info")
                            }}
                            selected={selectedControl === "Info"}
                        >
                            Info
                        </SegmentedControlItem>
                    </SegmentedControl>
                </Section>
            </>
            {selectedControl == "Transactions" ? 
                <Section>
                    {transactions !== null ? transactions.length > 0 ? transactions.map((transaction, index) => (
                        <TransactionData transaction={transaction} index={index} handleTransactionClick={handleTransactionClick} cardTransaction={true}/>
                    )) : <Placeholder style={{margin:"16px 0 16px 0"}} description="Transaction list is empty"></Placeholder> :  <div style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%" , height: "120px"}}>
                    <Spinner />
                </div>}
                </Section> 
            : selectedControl == "Actions" ?    
                <Section>
                    {datails.actions && datails.actions.length > 0 ? 
                    <>
                        {datails.actions?.includes("freeze") && <VibrateModule>
                            <Cell
                                onClick={() => setFreezeModal(true)} 
                                before={<Avatar size={48} src={FreezeImg} />}
                                style={{padding:"10px 16px"}}
                            >
                                {datails.status === "active" ? "Freeze" : datails.status === "freezed" ? "Unfreeze" : ""}
                            </Cell>
                        </VibrateModule>}

                        {datails.actions?.includes("topup") && <VibrateModule>
                            <Cell
                                onClick={() => setStatusTopup(true)} 
                                before={<Avatar size={48} src={TopupImg} />}
                                style={{padding:"10px 16px"}}
                            >
                                Top up
                            </Cell>
                        </VibrateModule>}

                        {datails.actions?.includes("withdraw") ? <VibrateModule>
                            <Cell
                                onClick={() => {
                                    return showNotification("Coming soon", "", "error");
                                    nav("/")
                                }}
                                before={<Avatar size={48} src={WithdrawImg} />}
                                style={{padding:"10px 16px"}}
                            >
                                Withdraw
                            </Cell>
                        </VibrateModule> : ''}

                    </> : <Placeholder style={{margin:"16px 0 16px 0"}} description="Action list is empty"></Placeholder>}

                    
                </Section>
            :
                <Section>
                    <VibrateModule>
                        <Cell
                            onClick={() => setFreezeModal(true)} 
                            before={<Avatar size={48} src={DownloadImg} />}
                            
                            subhead="For the current month"
                        >
                            Download statement
                        </Cell>
                    </VibrateModule>
                </Section>
            }
        </List>
    )
}

export default CardControllers