import { useEffect, useState } from "react";
import { checkPinCode, postPinCode } from "../api/api";
import ErrorPage from "../components/ErrorPage";
import Loader from "../helpers/loader";
import { Icon28BackspaceOutline, Icon28FaceIdOutline } from "@vkontakte/icons";
import { useNotification } from "../helpers/notification";
import Back from "../helpers/back";
import { Spinner, Text } from "@telegram-apps/telegram-ui";
import VibrateModule from "../helpers/vibrate";

const PinInputComponent = ({ setMe, me, fetchMe, functionSuccess = null, createPin = false, back = null }) => {
    const [pinCode, setPinCode] = useState([]);
    const [pinCodeRetry, setPinCodeRetry] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorBlock, setError] = useState(false);
    const [block, setBlock] = useState(false);

    const { showNotification } = useNotification();
    const [step, setStep] = useState(null);

    const handlePinCheck = async () => {
        if (createPin) {
            if (pinCodeRetry.join("") !== pinCode.join("")) {
                setPinCode([]);
                return showNotification("Error", "PINs do not match", "error");
            }
        }
        if (loading) return;
        try {
            setLoading(true);
            const res = await (!createPin ? checkPinCode({ pin: pinCode.join("") }) : postPinCode({ pin: pinCode.join("") }));
            console.log(res);
            if (res.status === "error") {
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 500);
                setLoading(false);
                setPinCode([]);
                if (res?.data?.pinAttemptsLeft === 0) {
                    setBlock(true);
                }
                if (res?.error?.message?.trim() === "Invalid PIN" && res?.data?.pinAttemptsLeft !== undefined) {
                    showNotification("Error", `${res.data.pinAttemptsLeft} attempts left`, "error");
                    return;
                }
                return showNotification("Error", res.error.message, "error");
            } else if (res.status === "success") {
                if (functionSuccess) {
                    await functionSuccess(pinCode);
                    setLoading(false);
                }
                if(fetchMe) {
                    await fetchMe();
                    setLoading(false);
                }
            }
        } finally {
           
        }
    };

    useEffect(() => {
        setStep(createPin ? 1 : 2);
    }, [createPin]);

    const handleNumberClick = (num) => {
        if(loading) return
        if (step === 1) {
            if (pinCodeRetry.length < 4) {
                setPinCodeRetry([...pinCodeRetry, num]);
            }
        } else if (step === 2) {
            if (pinCode.length < 4) {
                setPinCode([...pinCode, num]);
            }
        }
    };

    const handleBackspace = () => {
        if(loading) return
        if (step === 1) {
            setPinCodeRetry(pinCodeRetry.slice(0, -1));
        } else if (step === 2) {
            setPinCode(pinCode.slice(0, -1));
        }
    };

    useEffect(() => {
        if(loading) return
        if (pinCodeRetry.length === 4) {
            setStep(2);
        }
    }, [pinCodeRetry]);

    useEffect(() => {
        if(loading) return
        if (pinCode.length === 4) {
            handlePinCheck();
        }
    }, [pinCode]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            const { key } = event;
            if (loading) return;

            if (key >= '0' && key <= '9') {
                handleNumberClick(Number(key));
            } else if (key === 'Backspace') {
                handleBackspace();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [pinCode, pinCodeRetry, step, loading]);

    if (step === null) return <Loader />;
    if (block) return <ErrorPage type="pin" />;

    //console.log(window.Telegram.WebApp.BiometricManager.isBiometricAvailable)
    return (
        <>
            {back && (step === 2 && !createPin) && <Back callback={back} />}
            {back && (step === 2 && createPin) && <Back callback={() => setStep(1)} />}

            <div className="pinInput__page">
                <div className={`${errorBlock && "pinInput__error"}`}>
                    <Text style={{ display: "block", fontSize: "19px", textAlign: 'center', fontWeight: "600" }}>
                        {step === 1 ? "Enter new PIN" : "Enter your PIN"}
                    </Text>
                    <div className="pins">
                        {!loading ?  [...Array(4)].map((_, i) => (
                            <div key={i} className={`${Number((step == 1 ? pinCodeRetry : pinCode)[i]) >= 0  ? "active__pin" : ""} pin`}></div>
                        )) : <div className="pin__load" style={{display:'flex', justifyContent:"center"}}><Spinner style={{width:"13px", height:"13px"}}/></div>}
                    </div> 
                    <div className="pins__buttons">
                        <div className="pins__buttons__row">
                            {[1, 2, 3].map((num, idx) => (
                                <VibrateModule><div key={idx} onClick={() => handleNumberClick(num)}>{num}</div></VibrateModule>
                            ))}
                        </div>
                        <div className="pins__buttons__row">
                            {[4, 5, 6].map((num, idx) => (
                                <VibrateModule><div key={idx + 3} onClick={() => handleNumberClick(num)}>{num}</div></VibrateModule>
                            ))}
                        </div>
                        <div className="pins__buttons__row">
                            {[7, 8, 9].map((num, idx) => (
                                <VibrateModule><div key={idx + 6} onClick={() => handleNumberClick(num)}>{num}</div></VibrateModule>
                            ))}
                        </div>
                        <div className="pins__buttons__row">
                            <div className="pin__transparent"></div>
                            {/* window.Telegram.WebApp.BiometricManager.isBiometricAvailable && <VibrateModule><div onClick={() => window.Telegram.WebApp.BiometricManager.init()}><Icon28FaceIdOutline width={20} height={20} /></div></VibrateModule>*/}
                            <VibrateModule><div onClick={() => handleNumberClick(0)}>0</div></VibrateModule>
                            <VibrateModule><div className="backspace" onClick={handleBackspace}>
                                <Icon28BackspaceOutline width={32} height={32} />
                            </div></VibrateModule>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PinInputComponent;
