import { useEffect, useState } from "react";

const useFetch = (fetchFunction, args = []) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetchFunction(...args);
          setData(response.data);
          setError(null);
        } catch (err) {
          setError(err.message || "An error occurred");
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, [...args]);
  
    return { data, loading, error };
};

export default useFetch