import {
    Avatar,
    Badge,
    Button,
    Cell,
    Headline,
    Input,
    List,
    Placeholder,
    Section,
    Text,
    Title
} from "@telegram-apps/telegram-ui";
import { useLocation, useNavigate } from "react-router-dom";
import Back from "../helpers/back";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { getTopup, postWithdraw } from "../api/api";
import Loader from "../helpers/loader";
import QRCode from "qrcode.react";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import { se } from "date-fns/locale/se";
import VibrateModule from "../helpers/vibrate";
import MainButton from "../helpers/mainbutton";
import { useNotification } from "../helpers/notification";

const Withdraw = ({ me, rates }) => {
    const nav = useNavigate();
    const [topup, setTopup] = useState(null);
    const [selectedCur, setSelectedCur] = useState(null);
    const [selectedNet, setSelectedNet] = useState(null);
    const [step, setStep] = useState(1);
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [comment, setComment] = useState('');

    const [step1Done, setStep1Done] = useState(false);
    const [step2Done, setStep2Done] = useState(false);

    const {showNotification} = useNotification()

    const [loading, setLoading] = useState(false)

    /*
    async function fetchData() {
        const res = await getTopup();
        if (res.status === "error") {
            return showNotification("Error",res.error.message, "error") //toast.error();
        }
        setTopup(res.data);
    }*/

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedCurFromParams = queryParams.get("selectedCur");

    useEffect(() => {
        //fetchData();
        setSelectedCur(selectedCurFromParams ? selectedCurFromParams : "");
        if (selectedCurFromParams === "ton" || selectedCurFromParams === "bnb" || selectedCurFromParams === "trx"|| selectedCurFromParams === "bbx") {
            setStep1Done(true);
            setStep2Done(true);
            const selectedAddressFromParams = queryParams.get("address");
            const selectedAmountFromParams = queryParams.get("amount");

            if(selectedAddressFromParams) setAddress(selectedAddressFromParams)
            if(selectedAmountFromParams) setAmount(Number(selectedAmountFromParams))
            setStep(3);
        } else if (selectedCurFromParams) {
            setStep1Done(true);
            setStep(2)
        }
    }, [selectedCurFromParams]);

    const handleClick = () => {
        if (step === 1) {
            if(!selectedCur) return 
            if (selectedCur === "ton" || selectedCur === "trx" || selectedCur === "bnb" || selectedCur === "bbx" ) {
                setStep1Done(true);
                setStep2Done(true);
                setStep(3);
            } else {
                setStep(2);
                setStep1Done(true);
            }
        } else if (step === 2) {
            if(!selectedNet) return 
            setStep(3);
        } else if (step === 3) {
            handleConfirm();
        }
    };
    /*
    useEffect(() => {
        const mainButton = window.Telegram.WebApp.MainButton;

        const handleClick = () => {
            if (step === 1) {
                if (selectedCur === "ton" || selectedCur === "trx" || selectedCur === "bnb" || selectedCur === "bbx" ) {
                    setStep1Done(true);
                    setStep2Done(true);
                    setStep(3);
                } else {
                    setStep(2);
                    setStep1Done(true);
                }
            } else if (step === 2) {
                setStep(3);
            } else if (step === 3) {
                handleConfirm();
            }
        };

        if (step === 1 && selectedCur) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.enable();
            mainButton.setText("Confirm");
            mainButton.onClick(handleClick);
        } else if (step === 2 && selectedNet) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.setText("Confirm");
            mainButton.enable();
            mainButton.onClick(handleClick);
        } else if (step === 3) {
            mainButton.offClick(handleClick);
            mainButton.disable()
            mainButton.show();
            mainButton.enable();
            mainButton.setText("Confirm");
            mainButton.onClick(handleClick);
        }

        return () => {
            mainButton.offClick(handleClick);
            mainButton.hide();
        };
    }, [selectedCur, selectedNet, step, amount, address]);
*/
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            showNotification("Copied!", "", "success")
        })
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handleAmountChange = (e) => {
        setAmount(e.target.value);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const handleConfirm = async () => {

        if(loading) return
        setLoading(true)
        //window.Telegram.WebApp.MainButton.showProgress(true)
        try {
            const res = await postWithdraw({ currency: (selectedCur === "usd" && selectedNet === "trc20") ? "usdt-trc" : 
                (selectedCur === "usd" && selectedNet === "bep20") ? "usdt-bep" : (selectedCur === "usd" && selectedNet === "ton") ? "usdt-ton" : selectedCur , amount: Number(amount), address })
    
            if (res.status === "error") {
                return showNotification("Error", res.error.message, "error")
            } else {
                if(res.status === "success") {
                    showNotification("Successfully", "", "success")
                    return nav("/")
                }
                
            }
        } finally {
            setLoading(false)
            //window.Telegram.WebApp.MainButton.hideProgress()
        }
        

    };

    //if (topup === null) return <Loader />;
    /*if(me?.user?.sumsubVerified === false || !me?.user?.haveAccess) {
        if(me?.user?.sumsubVerified === false) {
            showNotification("Error","Go through verification", "error")  //toast.error("Go through verification")
        } else {
            showNotification("Error","You're on the wait list", "error")
            //toast.error()
        }
        return nav("/")
    }*/
    return (
        <List>
            <Back />
            

            {selectedCur === "usd" && step1Done ? (
                    <Section>
                        <VibrateModule>
                        <Cell style={{paddingLeft:"18px", paddingRight:"18px"}} onClick={() => {
                            
                            if (selectedCur === "usd") {
                                setStep1Done(false);
                                setStep2Done(false);
                                setStep(1);
                                return setSelectedCur(null);
                            }
                            setSelectedCur("usd");
                        }}  subtitle={`${me?.user?.balances?.usd ? Number(me?.user?.balances?.usd).toFixed(2): 0} USDT`} after={<Text>${me?.user?.balances?.usd ? (Number(me?.user?.balances?.usd)).toFixed(2) : 0}</Text>} before={<Avatar src="/img/usdt.png" />}>
                            Dollars
                        </Cell>
                        </VibrateModule>
                    </Section>
                ) : (step1Done && (selectedCur === "bnb" || selectedCur === "trx" || selectedCur === "ton" || selectedCur === "bbx")) && <Section>
                <VibrateModule>
                <Cell style={{paddingLeft:"18px", paddingRight:"18px"}} onClick={() => {
                    setStep1Done(false);
                    setStep2Done(false);
                    setStep(1);
                    return setSelectedCur(null);
                }}  subtitle={`${me?.user?.balances?.[selectedCur] ? Number(me?.user?.balances?.[selectedCur]).toFixed(2) : 0} ${String(selectedCur).toUpperCase()}`} after={<Text>${me?.user?.balances?.[selectedCur] ? (Number(me?.user?.balances?.[selectedCur]) * Number(rates?.[selectedCur]?.usd)).toFixed(2) : 0}</Text>} before={<Avatar src={`/img/${selectedCur}.png`} />}>
                    {selectedCur === "ton" ? "Toncoin":selectedCur === "bnb" ? "Binance Coin" : selectedCur === "trx" ? "TRON": "BITBIX"}
                </Cell>
                </VibrateModule>
            </Section>}

                
                {step === 1 ? (
                    <>
                    <Section>
                        <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE CURRENCY FOR WITHDRAW</SectionHeader>
                        {/*<VibrateModule>
                        <Cell onClick={() => {
                            return showNotification("Error", "Temporarily unavailable", "error")
                            if (selectedCur === "ton") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("ton");
                        }} disabled titleBadge={selectedCur === "ton" && <Badge type="dot" />} subtitle={`Temporarily unavailable`} before={<Avatar src="/img/ton.png" />}>
                            Toncoin
                        </Cell>
                        </VibrateModule>*/}
                        <VibrateModule>
                        <Cell subtitle={`${me?.user?.balances?.usd ? Number(me?.user?.balances?.usd).toFixed(2): 0} USD`} onClick={() => {
                            if (selectedCur === "usd") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("usd");
                        }} titleBadge={selectedCur === "usd" && <Badge type="dot" />}  after={<Text>${me?.user?.balances?.usd ? (Number(me?.user?.balances?.usd)).toFixed(2): 0}</Text>} before={<Avatar src="/img/usdt.png" />}>
                        Dollars
                        </Cell>
                        </VibrateModule>
                        {/* <Cell onClick={() => {
                            if (selectedCur === "trx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("trx");
                        }}  titleBadge={selectedCur === "trx" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.trx ? Number(me?.user?.balances?.trx).toFixed(2): 0} TRON`} after={<Text>${me?.user?.balances?.trx ? (Number(me?.user?.balances?.trx * Number(rates?.trx?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/trx.png"/>}>
                            TRON
                        </Cell>
                        <Cell onClick={() => {
                            if (selectedCur === "bnb") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bnb");
                        }} titleBadge={selectedCur === "bnb" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.bnb ? Number(me?.user?.balances?.bnb).toFixed(2) : 0} BNB`} after={<Text>${me?.user?.balances?.bnb ? (Number(me?.user?.balances?.bnb * Number(rates?.bnb?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/bnb.png"/>}>
                            Binance Coin
                        </Cell> */}
                        
                        {/*<VibrateModule>
                        <Cell onClick={() => {
                            if (selectedCur === "bbx") {
                                return setSelectedCur(null);
                            }
                            setSelectedCur("bbx");
                        }} titleBadge={selectedCur === "bbx" && <Badge type="dot" />} subtitle={`${me?.user?.balances?.bbx ? Number(me?.user?.balances?.bbx).toFixed(2) : 0} BBX`} after={<Text>${me?.user?.balances?.bbx ? (Number(me?.user?.balances?.bbx * Number(rates?.bbx?.usd))).toFixed(2) : 0}</Text>} before={<Avatar src="/img/bbx.png"/>}>
                            BITBIX
                        </Cell>
                        </VibrateModule>*/}
                    </Section>
                    {selectedCur && <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>}
                    </>
                ) : step === 2 ? (
                    <>
                    
                    <Section>
                        <SectionHeader style={{ paddingTop: "16px" }}>CHOOSE NETWORK FOR WITHDRAW</SectionHeader>
                        <VibrateModule>
                        <Cell style={{paddingTop:"6px", paddingBottom:"6px"}} onClick={() => {
                            if (selectedNet === "ton") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("ton");
                        }} titleBadge={selectedNet === "ton" && <Badge type="dot" />} before={<Avatar src="/img/ton.png" />}>
                            TON
                        </Cell>
                        </VibrateModule>
                        <VibrateModule>
                        <Cell disabled onClick={() => {
                            return showNotification("Coming soon", "", "error")
                            if (selectedNet === "trc20") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("trc20");
                            
                        }} description="Coming soon..." style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedNet === "trc20" && <Badge type="dot" />} before={<Avatar src="/img/trx.png" />}>
                            TRC-20
                        </Cell>
                        </VibrateModule>
                        {/* <Cell onClick={() => {
                            if (selectedNet === "bep20") {
                                return setSelectedNet(null);
                            }
                            setSelectedNet("bep20");
                        }} style={{paddingTop:"6px", paddingBottom:"6px"}} titleBadge={selectedNet === "bep20" && <Badge type="dot" />} before={<Avatar src="/img/usdt.png" />}>
                            BEP-20
                        </Cell> */}

                        {/*<Cell subtitle="100 USDT" after={<Text>$150</Text>} before={<Avatar src="/img/usdt.png" />}>
                        Tether USD
                        </Cell>
                        <Cell subtitle="100 BBX" after={<Text>$150</Text>} before={<Avatar src="/img/usdt.svg" />}>
                        BITBIX
                        </Cell>*/}
                    </Section>
                    <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar/>
                    </>
                ) : step === 3 ?(
                    <>
                    
                    <Section>
                        <List style={{marginBottom:"10px", marginTop:"10px"}}>
                            <Input placeholder="Address" value={address} onChange={handleAddressChange} />
                            <Input placeholder="Amount" type="number" value={amount} onChange={handleAmountChange} />
                        </List>
                    </Section>
                    <MainButton text={"Confirm"} func={handleClick} myButton withoutTabbar loading={loading}/>
                    </>
                ) : ""}
        </List>
    );
};

export default Withdraw;
