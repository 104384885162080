const base_url = "https://innature.store" //sandbox.bfinance.app
const token = window.btoa(window.Telegram.WebApp.initData) 
//const token = "cXVlcnlfaWQ9QUFGRUR6Uk5BQUFBQUVRUE5FMy1BektaJnVzZXI9JTdCJTIyaWQlMjIlM0ExMjk1MjU3NDEyJTJDJTIyZmlyc3RfbmFtZSUyMiUzQSUyMkRhbmlpbCUyMiUyQyUyMmxhc3RfbmFtZSUyMiUzQSUyMiUyMiUyQyUyMnVzZXJuYW1lJTIyJTNBJTIyZDlBMm5ZM0ElMjIlMkMlMjJsYW5ndWFnZV9jb2RlJTIyJTNBJTIyZW4lMjIlMkMlMjJpc19wcmVtaXVtJTIyJTNBdHJ1ZSUyQyUyMmFsbG93c193cml0ZV90b19wbSUyMiUzQXRydWUlN0QmYXV0aF9kYXRlPTE3MjQxNDA1MDAmaGFzaD01MGNhMjZjNmZlYmEyNjZlZjlhYTJkMTQyOTU1YmIzMTQyZmRjNDc3NjlmZTM1NDZhMmQzY2Q4NWRlM2YzODEx"

export async function fetchData(method, url, body = {}) {
    if(method === "GET") {
        try {
            const res = await fetch(`${base_url}${url}`, {
                headers: {
                    Authorization: token
                }
            }).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
        }
    } else if(method === "POST"){
        try {
            const res = await fetch(`${base_url}${url}`, {
                method: "POST",
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(body)
            }).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
        }
    }
}

//Qr 

export const postResolveQr = (data) => {
    return fetchData("POST", `/api/qr/resolve`, {data})
} 

//User

export const getUserInfo = (ref) => {
    return fetchData("GET", `/api/me${ref?`?ref=${ref}`:""}`)
} 

export const getPersonalData = () => {
    return fetchData("GET", `/api/me/personalData`)
} 

export const postPinCode = (data) => {
    return fetchData("POST", `/api/me/setPIN`, data)
} 

export const checkPinCode = (data) => {
    return fetchData("POST", `/api/me/checkPIN`, data)
} 

export const removePinCode = (data) => {
    return fetchData("POST", `/api/me/removePIN`, data)
} 

export const postKyc = () => {
    return fetchData("POST", `/api/kyc`)
}

export const getTopup = () => {
    return fetchData("GET", "/api/topup")
} 

export const postAddress = (body) => {
    return fetchData("POST", `/api/topup`, body)
}

export const postWithdraw = (body) => {
    return fetchData("POST", `/api/withdraw`, body)
}

export const getTransfer = (body) => {
    return fetchData("GET", `/api/transfer/check?entity=${body}`)
}

export const postTransfer = (body) => {
    return fetchData("POST", `/api/transfer`, body)
}

//Cards

export const getCards = () => {
    return fetchData("GET", "/api/cards")
} 

export const getCard = (id) => {
    return fetchData("GET", `/api/cards/${id}`)
}

export const getCardsOpen = (id) => {
    return fetchData("GET", `/api/cards/list`)
}


export const postCard = (body) => {
    return fetchData("POST", "/api/cards", body)
}

//Rates
export const getRates = () => {
    return fetchData("GET", "/api/rates")
} 

//History

export const getHistory = () => {
    return fetchData("GET", "/api/me/history")
}

export const getHistoryCur = (cur) => {
    return fetchData("GET", `/api/me/history?currency=${cur}`)
}



//Earn
export const getTasks = () => {
    return fetchData("GET", "/api/tasks")
}

export const postEarn = (id) => {
    return fetchData("POST", "/api/tasks/"+id)
}



//Card

export const getSensetive = (id) => {
    return fetchData("GET", `/api/cards/${id}/sensetive`)
}

export const getCardBalance = (id) => {
    return fetchData("GET", `/api/cards/${id}/balance`);
};

export const getCardTransactions = (id) => {
    return fetchData("GET", `/api/cards/${id}/transactions`);
};

export const topupCard = (id, body) => {
    return fetchData("POST", `/api/cards/${id}/topup`, body)
}


export const getCardDetails = (id) => {
    return fetchData("GET", `/api/cards/${id}/details`)
}

export const postFreeze = (id) => {
    return fetchData("POST", `/api/cards/${id}/freeze`)
}

export const postUnfreeze = (id) => {
    return fetchData("POST", `/api/cards/${id}/unfreeze`)
}


export const postDelete = (id) => {
    return fetchData("POST", `/api/cards/${id}/delete`)
}


//Payments 

export const chooseCountry = (country) => {
    return fetchData("POST", `/api/payments/country`, {country: country})
}

export const getCategories = (country) => {
    return fetchData("GET", `/api/payments/${country}/categories`)
}

export const getServices = (country, category) => {
    return fetchData("GET", `/api/payments/${country}/${category}`)
}

export const getCountries = () => {
    return fetchData("GET", `/api/payments/countries`)
}

//Phone top-up

export const getPhoneTopupProducts = (data) => {
    return fetchData("POST", `/api/payments/phone/products`, data)
}

export const postPhoneTopupPayment = (data) => {
    return fetchData("POST", `/api/payments/phone/estimate`, data)
}

export const postPhoneTopup = (data) => {
    return fetchData("POST", `/api/payments/phone`, data)
}


//eSIM

export const getProductsByCountry = (data) => {
    return fetchData("GET", `/api/payments/esim/products/country/${data}`)
}
export const getProductsByRegion = (data) => {
    return fetchData("GET", `/api/payments/esim/products/region/${data}`)
}
export const getProductsByGlobal = (data) => {
    return fetchData("GET", `/api/payments/esim/products/global`)
}
export const getESIMs = () => {
    return fetchData("GET", `/api/esims`)
}
export const posteSIM = (data) => {
    return fetchData("POST", `/api/payments/esim/buy`, data)
}

//Payment QR

export const getPaymentData = (id) => {
    return fetchData("GET", `/api/payments/esim/products/country/${id}`)
}

//To card

export const sendToCard = (body) => {
    return fetchData("POST", `/api/payments/transfers/card`, body)
}