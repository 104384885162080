import { Cell, List, Section } from "@telegram-apps/telegram-ui";
import MainButton from "../../../helpers/mainbutton";
import VibrateModule from "../../../helpers/vibrate";
import { SectionHeader } from "@telegram-apps/telegram-ui/dist/components/Blocks/Section/components/SectionHeader/SectionHeader";
import Back from "../../../helpers/back";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getPersonalData } from "../../../api/api";
import Loader from "../../../helpers/loader";
import { useNotification } from "../../../helpers/notification";

export const PersonalData = ({ me, setPersonalDataStatus }) => {
    const [personal, setPersonal] = useState(null);
    const [loading, setLoading] = useState(false);
    const {showNotification} = useNotification()

    async function fetchData() {
        try {
            setLoading(true);
            const res = await getPersonalData();
            if (res.status === "error") {
                return showNotification("Error", res.error.message, "error") 
            }
            setPersonal(res.data);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (personal === null) return <Loader />;
    return (
        <>
            <Back callback={() => setPersonalDataStatus(false)} />

            <List>
                <Section>
                    <SectionHeader style={{ paddingTop: "16px" }}>
                        Personal data
                    </SectionHeader>
                    <VibrateModule>
                        <Cell description="First name">
                            {personal.firstName || "-"}
                        </Cell>
                        <Cell description="Last name">
                            {personal.lastName || "-"}
                        </Cell>
                        <Cell description="Nationality">
                            {personal.country || "-"}
                        </Cell>
                        <Cell description="Phone number">
                            {personal.phone || "-"}
                        </Cell>
                    </VibrateModule>
                </Section>
                {(personal.address && personal?.address?.country) && (
                    <Section>
                        <SectionHeader style={{ paddingTop: "16px" }}>
                            Address
                        </SectionHeader>
                        <VibrateModule>
                            <Cell description="Country">
                                {personal.address.country || "-"}
                            </Cell>
                            <Cell description="City">
                                {personal.address.city || "-"}
                            </Cell>
                            <Cell description="Street">
                                {personal.address.street || "-"}
                            </Cell>
                        </VibrateModule>
                    </Section>
                )}
            </List>
            <MainButton
                text={"Update"}
                func={() => {
                    window.open("https://t.me/bfinsupbot")
                }}
                withoutTabbar
                myButton
                //loading={loading}
            />
        </>
    );
};
