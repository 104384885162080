import { Button } from "@telegram-apps/telegram-ui";
import React, { useEffect } from "react";
import VibrateModule from "./vibrate";

const MainButton = ({text, func, loading, myButton = false, withoutTabbar = false}) => {

  
  useEffect(() => {
    if(!myButton) {
      window.Telegram.WebApp.MainButton.onClick(func);
      window.Telegram.WebApp.MainButton.setText(text);
      window.Telegram.WebApp.MainButton.show();
      window.Telegram.WebApp.MainButton.enable();
  
      return () => {
        window.Telegram.WebApp.MainButton.hide();
        window.Telegram.WebApp.MainButton.offClick(func);
      };
    }
      
  }, [func, myButton]);

  useEffect(() => {
    if(!myButton) {
      if(loading) {
        window.Telegram.WebApp.MainButton.showProgress(loading)
      } else {
        window.Telegram.WebApp.MainButton.hideProgress()
      }

      return () => {
        window.Telegram.WebApp.MainButton.hideProgress()
      };
    }
  },[loading, myButton])

  return myButton ? <VibrateModule><Button  loading={loading} onClick={func} mode="outline" className="main__button" style={{bottom: withoutTabbar && "24px"}}>
    {text}
  </Button></VibrateModule>: <></>
};

export default MainButton;
