import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useNotification } from "../helpers/notification";

const PermissionLayout = ({ children, me, withoutVerif = false }) => {
    const nav = useNavigate();
    const { showNotification } = useNotification();

    useEffect(() => {
        if(withoutVerif === true) {
            /*if (!me?.user?.haveAccess) {
                showNotification("Error", "You're on the wait list", "error");
                nav("/");
            }*/
            return
        }
        if (me?.user?.sumsubVerified === false /*|| !me?.user?.haveAccess*/) {
            if (me?.user?.sumsubVerified === false) {
                showNotification("Error", "Go through verification", "error");
            } /*else {
                showNotification("Error", "You're on the wait list", "error");
            }*/
            nav("/");
        }
    }, [me]);

    /*if (!me?.user?.haveAccess) {
        return null;
    }*/

    return (
        <>
            {children}
        </>
    );
};

export default PermissionLayout;
