import { Section, Text } from "@telegram-apps/telegram-ui"
import MainButton from "../../../../helpers/mainbutton"



const GiftCardService = () => {
    const handleBuyGiftCard = () => {
        console.log("dada")
    }
    
    return (
        <>
            <Section header={"GIFT CARD"}></Section>
            <img src="/card/gp.png" className="geft__card__banner"/>
            <Text style={{padding:"12px 0", display:"block"}}>
                dasdasdadadad
            </Text>
            
        
            <MainButton text={"Buy"} func={() => handleBuyGiftCard()} myButton/>
        </>
    )
}

export default GiftCardService