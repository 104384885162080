import { useState, useEffect, useRef } from "react";
import { Avatar, Cell, Placeholder, Section } from "@telegram-apps/telegram-ui";
import VibrateModule from "../../../../../helpers/vibrate";

const CountryList = ({ countries, searchQuery, handleChooseCountry }) => {
    const [displayedCountries, setDisplayedCountries] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = 20;
    const loadMoreRef = useRef(null);

    const filterCountries = (countries, query) => {
        console.log(searchQuery)
        return countries.filter(country =>
            country.name.toLowerCase().includes(String(query ? query : '').toLowerCase())
        );
    };

    const loadMoreCountries = () => {
        const filteredCountries = filterCountries(countries, searchQuery);
        if (displayedCountries.length >= filteredCountries.length) return;

        const nextPage = page + 1;
        const nextCountries = filteredCountries.slice(0, nextPage * itemsPerPage);
        setDisplayedCountries(nextCountries);
        setPage(nextPage);
    };

    useEffect(() => {
        setPage(1); 
        const filteredCountries = filterCountries(countries, searchQuery);
        setDisplayedCountries(filteredCountries.slice(0, itemsPerPage)); 
    }, [searchQuery, countries]); 

    useEffect(() => {
        console.log('Setting up IntersectionObserver');
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {
                loadMoreCountries();
            }
        }, {
            root: null,
            rootMargin: "100px",
            threshold: 1.0,
        });

        if (loadMoreRef.current) {
            observer.observe(loadMoreRef.current);
        }

        return () => {
            if (loadMoreRef.current) {
                observer.unobserve(loadMoreRef.current);
            }
        };
    }, [displayedCountries]); 

    return (
        <>
            {displayedCountries.length > 0 ? (
                <Section header={"Select country"}>
                    {displayedCountries.map((country, index) => (
                        <VibrateModule>
                        <Cell
                            key={index}
                            style={{ margin: "2px 0" }}
                            before={<Avatar acronym={String(country.name).charAt(0)} size={28} src={country.flagUrl} />}
                            onClick={() => handleChooseCountry(country)}
                        >
                            {country.name}
                        </Cell>
                        </VibrateModule>
                    ))}
                </Section>
            ) : (
                <Section header={"Select country"}>
                    <Placeholder style={{ margin: "16px 0" }} description={"Country list is empty"} />
                </Section>
            )}
            <div ref={loadMoreRef} style={{ height: '1px', margin: '1px' }} />
        </>
    );
};

export default CountryList;
